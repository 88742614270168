import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './../app/store/auth/AuthInit'
import {AppLayoutProvider} from '../_metronic/layout/core/AppLayoutProvider'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AppLayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </AppLayoutProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
