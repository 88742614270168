import {IUrlParameters} from '../components/lists/UrlFilteredList'

const TOKEN_COOKIE = '_token'
const REFRESH_TOKEN_COOKIE = '_token-refresh'

export {TOKEN_COOKIE, REFRESH_TOKEN_COOKIE}

// timeout to typing. time between pressing keys
export const SEARCH_DEBOUNCE_TIMEOUT = 1000

export const DEFAULT_CHANGED_ID = -2

let resultType: 'direct' | 'data' | 'data.result' | 'data.results' | 'data.categories'
export type TSagaResultType = typeof resultType

export const emptyUrlParameters: IUrlParameters = {
  filter: '',
  itemsPerPage: 10,
  ordering: '',
  pageNumber: 0,
  url: '',
}

export interface IAdditionalPutActionWithParams {
  action: any
  params: any
}

export interface IAdditionalPutActionWithExtendedParams {
  action: any
  params?: any
  resultType?: TSagaResultType // from what object we get additional property
  resultTypePropertyName?: string // if we want to use results in params, we can set property name of data here
}

export type TEnumMarketplacesNames =
  | 'Ozon'
  | 'Wildberries'
  | 'YandexMarket'
  | 'Avito'
  | 'SberMarket'
  | 'Aliexpress'

type TEnumMarketplacesIds = 1 | 2 | 3 | 4 | 5 | 6

export const EnumMarketplacesIds = {
  Ozon: 2 as TEnumMarketplacesIds,
  Wildberries: 1 as TEnumMarketplacesIds,
  YandexMarket: 5 as TEnumMarketplacesIds,
  Avito: 6 as TEnumMarketplacesIds,
  SberMarket: 4 as TEnumMarketplacesIds,
  Aliexpress: 3 as TEnumMarketplacesIds,
}

export const EnumMarketplacesNames = {
  Ozon: 'Ozon' as TEnumMarketplacesNames,
  Wildberries: 'Wildberries' as TEnumMarketplacesNames,
  YandexMarket: 'YandexMarket' as TEnumMarketplacesNames,
  Avito: 'Avito' as TEnumMarketplacesNames,
  SberMarket: 'SberMarket' as TEnumMarketplacesNames,
  Aliexpress: 'Aliexpress' as TEnumMarketplacesNames,
}
