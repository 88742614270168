import BaseCustomButton, { BaseCustomButtonProps } from "./BaseCustomButton"



const CloseButton: React.FC<BaseCustomButtonProps> = (props) => {
    return (
        <BaseCustomButton {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
            </svg>
            <span className={props.childrenClassName ? props.childrenClassName : ''}>{props.children}</span>
        </BaseCustomButton>)
}


export default CloseButton