import {useEffect, useState, ChangeEvent} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {updateData} from '../../../../../store'

import {Form} from 'react-bootstrap'

import {StorageType} from '../../../../../../setup'
import {TYMAccount, TAccNames} from '../../../../../store/mpWizard/types'

const YMStep4 = () => {
  const currentStep = useSelector((state: StorageType) => state.mpWizard.currentStep)
  const data = useSelector((state: StorageType) => state.mpWizard.data)

  const selectedYMAccounts: TYMAccount[] = data?.step1?.selectedYMAccounts

  const currentCustomerCompanyNames = data[`step${currentStep}`] || {}

  const initAccNames = selectedYMAccounts.map((acc) => ({
    accId: acc.id,
    shDAccName: `${acc.business.name} (${acc.domain})`,
    accName: currentCustomerCompanyNames[acc.id] || '',
  }))

  const [accNames, setAccNames] = useState<TAccNames[]>(initAccNames)

  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(currentCustomerCompanyNames).length === 0) {
      selectedYMAccounts.forEach((acc) => {
        dispatch(updateData({key: 'step3', field: `${acc.id}`, value: null}))
      })
    }
  }, [])

  const onChange = (accId: number) => (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateData({key: 'step3', field: `${accId}`, value: e.target.value}))

    const newAccNames = accNames.map((accName) => {
      if (accName.accId === accId) {
        return {...accName, accName: e.target.value}
      }
      return accName
    })

    setAccNames(newAccNames)
  }

  return (
    <div className='fs-5'>
      <p className='mb-9'>
        Пожалуйста, введите название вашего кабинета. Это поможет вам легко идентифицировать и
        управлять своими аккаунтами в будущем. Название может быть любым, удобным для вас, и оно
        будет использоваться только в рамках нашего сервиса.
      </p>

      {accNames.map((accName) => (
        <div key={accName.accId} className='d-flex align-items-center mb-5'>
          <Form.Label className='text-dark me-8 mb-0' style={{width: '250px'}}>
            {accName.shDAccName}
          </Form.Label>
          <Form.Control
            value={accName.accName}
            onChange={onChange(accName.accId)}
            className='bg-secondary w-50'
            placeholder='Название кабинета'
          />
        </div>
      ))}
    </div>
  )
}

export default YMStep4
