import {FC} from 'react'
import {TCompany} from '../../../../../store/mpWizard/types'
import {useIntl} from 'react-intl'
import styles from './LegalEntityInfo.module.scss'

interface ICustomerCompanyInfoProps {
  customerCompany: TCompany | null
}

const LegalEntityInfo: FC<ICustomerCompanyInfoProps> = ({customerCompany}) => {
  const intl = useIntl()

  return (
    <div className='mb-8'>
      <p className={styles.customerInfoText}>
        {intl.formatMessage({id: 'COMPONENTS.LEGAL_ENTITY.NAME'})}:{' '}
        <span>{customerCompany?.name}</span>
      </p>
      <p className={styles.customerInfoText}>
        {intl.formatMessage({id: 'COMPONENTS.LEGAL_ENTITY.ADDRESS'})}:{' '}
        <span>{customerCompany?.address}</span>
      </p>
      <p className={styles.customerInfoText}>
        {intl.formatMessage({id: 'COMPONENTS.LEGAL_ENTITY.INN'})}:{' '}
        <span>{customerCompany?.inn}</span>
      </p>
      <p className={styles.customerInfoText}>
        {intl.formatMessage({id: 'COMPONENTS.LEGAL_ENTITY.KPP'})}:{' '}
        <span>{customerCompany?.kpp}</span>
      </p>
    </div>
  )
}

export default LegalEntityInfo
