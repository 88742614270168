import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {ISelectOption} from '../../components/common/select/AsyncSelectInput'
import {
  IGoodCardPrice,
  IGoodMPCard,
  IGoodTradeOffer,
  TGoodOfferStatus,
} from '../../modules/goods/types'
import {EMPTY_CATEGORY, ICategory} from '../categories/types'
import {IGoodFieldAttributeType} from '../goods/api'
import {IGood, IGoodShort} from '../goods/types'
import {IImage} from '../images/types'
import {IMarketplace} from '../marketplaces/types'
import {IStockItem} from '../stocks/types'

export const DEFAULT_MPCARDS_LIMIT = 10
export const NEW_MPCARD_ID = -2

export const emptyMpCardFilterAdditionalParameters: ISelectOption[] = [
  {
    label: 'marketplace',
    value: '',
  },
  {
    label: 'account',
    value: '',
  },
]

export const emptyMpCardFilterParameters: IUrlParameters = {
  //   ...emptyUrlParameters,
  filter: '',
  itemsPerPage: 10,
  ordering: '',
  pageNumber: 0,
  url: '/goods/mpcards/list/',
  additionalParameters: emptyMpCardFilterAdditionalParameters,
}

export const emptyState: IMpCardsStorage = {
  items: [],
  templateItems: [],
  templateItemsIsLoading: false,
  selected: null,
  isLoading: false,
  isUniversalMpCardLoading: false,
  isUniversalMpCardSaving: false,
  lastOperationIsSuccess: true,
  count: 0,
  filter: {...emptyMpCardFilterParameters},
  name: null,
  selectedUniversal: null,

  // sku_id: null,
  //ordering: "",
  // search: "",
  // currentPage: 0,
  // itemsPerPage: DEFAULT_MPCARDS_LIMIT,
  // account: null,
  // company: null,
  // good_name: null,
  // good_id: null,
  // good_uid: null,
  // marketplace: null,
  // mpcard_id: null,

  // unlinkedItems: [],
  // matchesItem: {account: -1, matches: []},
  // tradeOffers: []
}

// export const emptyStockItem: IStockItem = {
//     id: 0,
//     warehouse: 0,
//     sku_customer_uid: "",
//     quantity: 0,
//     reserved: 0,
// }

export interface ISelectedMpCardAdditionalData {
  sku_id?: number
  good_id?: number
  marketplace_name?: string
  account_name?: string
}

export interface IMpCardsStorage {
  count: number | null
  items: IGoodTradeOffer[]
  templateItems: IMpCardTemplate[]
  templateItemsIsLoading: boolean
  selected: IGoodTradeOffer | null
  isLoading: boolean
  isUniversalMpCardLoading: boolean
  isUniversalMpCardSaving: boolean
  lastOperationIsSuccess: boolean
  filter: IUrlParameters
  name?: string | null
  selectedUniversal?: IGoodMpCardUniversal | null
  selectedUniversal_data?: ISelectedMpCardAdditionalData

  // selectedUniversal_data?: number
  // sku_id?: string | null
  //ordering:  string
  // search:  string
  // currentPage:  number
  // itemsPerPage: number
  // account?: number | null
  // company?: number | null
  // good_name?: string | null
  // good_id?: number | null
  // good_uid?: string | null
  // marketplace?: number | null
  // mpcard_id?: number | null

  // unlinkedItems?: IMPCardExtended[]
  // matchesItem?: IMpCardMatchAccount
  // tradeOffers?: IGoodTradeOffer[]
}

export interface IGoodMpCardUniversalValue {
  value?: string
  value_id?: string
  count?: number
  unit?: string | null
}

export interface IGoodMpCardUniversalVariant {
  name: string | null
  value: string | null
}

export interface IGoodMpCardUniversalCardVariant {
  attributes: IGoodMpCardUniversalAttribute[] | null
  images: string[] | null
  name: string
  offer_id?: number // TODO add it on server
  is_edit?: boolean
  is_empty?: boolean
  is_new?: boolean
  is_current?: boolean
  mpcard_id?: number
}

export const emptyGoodMpCardUniversalCardVariant: IGoodMpCardUniversalCardVariant = {
  attributes: [],
  images: [],
  name: '',
}

let Locations: 'offer' | 'product' | 'both'
export type TLocations = typeof Locations

export interface IGoodMpCardUniversalAttribute {
  id: number | null
  name: string
  code?: string | null
  address?: any
  description: string
  value: IGoodMpCardUniversalValue[]
  dict_link: string //link to dictioanary values (select options). add parameter  '?query=search phrase' to filter dictionary values
  only_dict: boolean // if true (default), there values only from dictionary (false - you can add your own values)
  value_type: IGoodFieldAttributeType
  allow_multiply: boolean // allow multiple value
  variants?: IGoodMpCardUniversalVariant[] | null
  required: boolean //is required field
  read_only: boolean // is readonly field. can't edit it
  hidden: boolean // do not show field
  location?: TLocations
  complex: boolean // if true, then value { value and count will be required}
}

export interface IMpCardUniversalAttributeValue {
  id: string
  value: string
}

export interface IGoodMpCardUniversal {
  has_offers: boolean
  attributes: IGoodMpCardUniversalAttribute[]
  variants: IGoodMpCardUniversalCardVariant[] | null
  images: string[]
  account_id: number | null
  marketplace?: number | null
  category?: ICategory | null
  show_attributes_in_variants?: boolean // show attributes of subcards (true) or add it manual (false)
  id: number

  is_published?: boolean | null // true - published, false - unpublished, if null - mpcard can't publish/unpublish

  // custom fields to use it in component (do not save it)
  account_name?: string | null
  marketplace_name?: string | null

  // for creaing new card (not for saving)
  new_sku_id?: number
  new_good_id?: number

  // offer_attributes: IGoodMpCardUniversalAttribute[]
}

export const emptyGoodMpCardUniversal: IGoodMpCardUniversal = {
  has_offers: false,
  attributes: [],
  // offer_attributes: [],
  variants: [],
  images: [],
  account_id: 0,
  marketplace: 0,
  category: EMPTY_CATEGORY,
  id: -1, // change from 0 (at 18.09.23)
}

export const emptyGoodMpCardUniversalAttribute: IGoodMpCardUniversalAttribute = {
  id: null,
  name: '',
  description: '',
  value: [{count: 0, value: '0', value_id: '0'}],
  dict_link: '', //link to dictioanary values (select options). add parameter  '?query=search phrase' to filter dictionary values
  only_dict: true, // if true (default), there values only from dictionary (false - you can add your own values)
  value_type: 'String',
  allow_multiply: false, // allow multiple value
  variants: null,
  required: false,
  read_only: false,
  hidden: false,
  complex: false,
}

export interface IMpCardListRequestParams {
  limit: number
  search?: string
  ordering?: string | null
  offset?: number | null
  page?: number | null

  account?: number | null
  company?: number | null
  good_name?: string | null
  good_id?: number | null
  good_uid?: string | null
  marketplace?: number | null
  mpcard_id?: number | null
  name?: string | null
  sku_id?: string | null
}

export interface IMpCardItemRequestParams {
  id: number
}

export interface IMpCardItemPublishParams {
  id: number
  action: 'publish' | 'unpublish'
}

export interface IMpCardItemPublishResponse {
  is_published: boolean
}

export interface IMpCardListResponse {
  count: number
  results: IGoodTradeOffer[]
}

export interface IGoodMpCardUniversalRequest {
  card: IGoodMpCardUniversal
  id: number
}

export interface INewMpCardParams {
  sku_id: number
  good_id: number
  account_id: number
  payload: IGoodMpCardUniversal
  params?: any
  id: number
}

export interface IMpCardSavingResult {
  isSuccess: boolean
  action: 'saving' | 'creating'
  message: string
}

export const emptyNewMpCardParams: INewMpCardParams = {
  sku_id: -1,
  good_id: -1,
  account_id: -1,
  payload: emptyGoodMpCardUniversal,
  id: NEW_MPCARD_ID,
}

export interface ISku {
  compititors: []
  customer_uid: string
  id: number
  images: IImage[]
  mpcards: any
  name: string
  price_competitor: []
  prices: IGoodCardPrice[]
  stock: IStockItem[]
}

export interface IMPCardExtended_Old {
  id: number
  sku: ISku[] | [] | null
  good: IGoodShort
  marketplace: IMarketplace | null // marketplace id
  company: number | null // company id
  params?: {
    status?: TGoodOfferStatus //"failed" | "processing" |"finished" | null // "finished",
    task_id?: string | null //"448702830",
    product_id?: string | null // 256176073,
    need_update?: boolean | null //false,
    need_to_update?: boolean | null //false
    unlinked_items?: number | null
  } | null
  created: Date | string | null
  name?: string
  account_id: number | null
  account_name: string | null
  price: IGoodCardPrice | null
  url_on_marketplace?: string
  payload?: any
}
export interface IMPCardExtended {
  id: number
  sku: ISku[] | [] | null
  good: IGoodShort
  marketplace: number | null // marketplace id
  company: number | null // company id
  params?: {
    status?: TGoodOfferStatus //"failed" | "processing" |"finished" | null // "finished",
    task_id?: string | null //"448702830",
    product_id?: string | null // 256176073,
    need_update?: boolean | null //false,
    need_to_update?: boolean | null //false
    unlinked_items?: number | null
  } | null
  created: Date | string | null
  deleted: Date | string | null
  name?: string
  account: number | null
  price: IGoodCardPrice | null
  url_on_marketplace?: string
  payload?: any
}

export interface IMpCardMatchResponse {
  success: boolean
  result: IMpCardMatchAccount
}

export interface IMpCardMatchAccount {
  account: number
  matches: IMpCardMatchItem[]
}

export interface IMpCardMatchItem {
  match_id: number
  code: string
  sku: number
  sku_name: string
  sku_customer_uid: string
}

export interface IMpCardMatchSaveItem {
  id: number | null
  sku: number
  code: string
}

export interface IMpCardMatchSaveRequest {
  mpcard: number
  matches: IMpCardMatchSaveItem[]
}

export const emptyMpCardMatchSaveItem: IMpCardMatchSaveRequest = {
  mpcard: -1,
  matches: [],
}

export interface IMpCardTemplate {
  id: number
  name: string
  marketplace: number
  good: IGoodShort
  created?: string
  updated?: string
  payload?: IGoodMpCardUniversal
  params?: any

  category?: ICategory // category to choose mpcard fields
  isButton?: boolean // if true - render button in controls
  buttonClick?: any // handle to button's click
}

export const emptyGoodShort: IGoodShort = {
  id: 0,
  name: '123',
  customer_uid: '123',
  // owner: 0,
}

export const emptyMpCardTemplate: IMpCardTemplate = {
  id: -1,
  name: '',
  marketplace: -1,
  good: emptyGoodShort, //null,
  // payload: emptyGoodMpCardUniversal
}

export interface IMpCardTemplateCreateParams {
  name: string
  payload?: IGoodMpCardUniversal
  params?: any
  marketplace_id: number
  good_id: number
}

export const emptyMpCardTemplateCreateParams: IMpCardTemplateCreateParams = {
  name: '',
  marketplace_id: -1,
  good_id: -1,
}

export interface IMpCardTemplateGetParams {
  good?: string // id through comma
  limit?: number
  marketplace?: string // id through comma
  offset?: number
  ordering?: string
  search?: string
}

export const mpCardActionTypes = {
  GetMpCards: '[GetMpCards] Action',
  GetMpCard: '[GetMpCard] Action',
  GetUniversalMpCard: '[GetUniversalMpCard] Action',
  NewUniversalMpCard: '[NewUniversalMpCard] Action',
  // GetUnlinkedMpCards: '[GetUnlinkedMpCards] Action',
  // GetTradeOffers: '[GetTradeOffers] Action',
  // GetMatches: '[GetMatches] Action',
  SaveUniversalMpCard: '[SaveUniversalMpCard] Action',
  CreateUniversalMpCard: '[CreateUniversalMpCard] Action',
  UpdateMpCard: '[UpdateMpCard] Action',
  PublishMpCard: '[PublishMpCard] Action',
  PublishMpCard2: '[PublishMpCard2] Action',
  SetParametersMpCard: '[SetParametersMpCard] Action',
  AddParametersMpCard: '[AddParametersMpCard] Action',
  SetMarketplaceParameter: '[SetMarketplaceParameter] Action',
  SetAccountParameter: '[SetAccountParameter] Action',
  SetMpCardPrice: '[SetMpCardPrice] Action',
  SetMpCardPriceFromGoodItem: '[SetMpCardPriceFromGoodItem] Action',
  ResetMpCardSelectedUniversal: '[ResetMpCardSelectedUniversal] Action',
  SetMpCardSelectedUniversal: '[SetMpCardSelectedUniversal] Action',
  SetSelectedUniversalMpCard: '[SetSelectedUniversalMpCard] Action',
  ResetParametersMpCard: '[ResetParametersMpCard] Action',
  SetMpCardIsSaving: '[SetMpCardIsSaving] Action',

  GetMpCardTemplates: '[GetMpCardTemplates] Action',
  CreateMpCardTemplate: '[CreateMpCardTemplate] Action',
  UpdateMpCardTemplate: '[UpdateMpCardTemplate] Action',
  CopyMpCardTemplate: '[CopyMpCardTemplate] Action',
  DeleteMpCardTemplate: '[DeleteMpCardTemplate] Action',
  SetMpCardTemplateIsLoading: '[SetMpCardTemplateIsLoading] Action',
}
