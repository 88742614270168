import {FC, useState, useRef, useEffect, ChangeEvent, KeyboardEvent, FocusEvent} from 'react'

interface IOTPInputProps {
  values: string[]
  isLoading?: boolean
  isDisabled?: boolean
  error?: string
  onOTPSubmit: (otp: string) => void
  onFocusInput?: () => void
}

const OTPInput: FC<IOTPInputProps> = ({
  onOTPSubmit,
  isLoading,
  onFocusInput,
  error,
  isDisabled,
  values,
}) => {
  const [otp, setOTP] = useState(values)

  const inputRefs = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    inputRefs.current[0].focus()
  }, [])

  useEffect(() => {
    setOTP(values)
  }, [values])

  const onChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (isNaN(Number(value))) {
      inputRefs.current[index].value = ''
      return
    }

    const newOTP = [...otp]
    newOTP[index] = value

    setOTP(newOTP)

    const combinedOTP = newOTP.join('')

    if (combinedOTP.length === values.length) {
      inputRefs.current[index].blur()

      onOTPSubmit(combinedOTP)
    }

    if (value && index < values.length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus()
    }
  }

  const onClick = (index: number) => () => {
    inputRefs.current[otp.indexOf('')]?.focus()
  }

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocusInput) {
      onFocusInput()
    }

    if (error) {
      setOTP(new Array(values.length).fill(''))
    }
  }

  const onKeyDown = (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && index > 0 && !otp[index] && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus()
    }
  }

  return (
    <div className='w-100 mb-5'>
      <div className='w-50 d-flex justify-content-center gap-2 mx-auto'>
        {otp.map((value, index) => (
          <input
            ref={(e) => {
              if (e) {
                inputRefs.current[index] = e
              }
            }}
            disabled={isLoading || isDisabled}
            onFocus={onFocus}
            onChange={onChange(index)}
            onClick={onClick(index)}
            onKeyDown={onKeyDown(index)}
            autoComplete='off'
            inputMode='numeric'
            key={index}
            maxLength={1}
            value={value}
            className='form-control form-control-solid text-center fs-2 fw-bolder'
            type='text'
          />
        ))}
      </div>
      {error && <p className='text-center fs-6 mb-5 text-danger'>{error}</p>}
    </div>
  )
}

export default OTPInput
