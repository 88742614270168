import {takeLatest, takeEvery, select} from 'redux-saga/effects'
import {
  setInvoiceData,
  setIsLoading,
  setInvoiceCreateDocument,
  setIsRenewingDocument,
} from './reducer'
import {
  FETCH_INVOICES,
  CREATE_PAYMENT_DOCUMENT,
  CREATE_PAYMENT_OPTIONAL_DOCUMENT,
  PRINT_PAYMENT_DOCUMENT,
} from './actions'
import {sagaUtils} from '../../utils/dataUtils'
import {intl} from '../../modules/goods/item/validation'
import {StorageType} from '../../../setup'
import {IInvoicesStorage} from './types'
import {
  fetchInvoices,
  createNewPaymentDocument,
  createNewPaymentOptionalDocument,
  printNewPaymentDocument,
} from './api'

export function* invoicesSaga() {
  yield takeLatest(FETCH_INVOICES, workerFetchInvoices)
  yield takeEvery(PRINT_PAYMENT_DOCUMENT, workerPrintPaymentDocument)
  yield takeEvery(CREATE_PAYMENT_DOCUMENT, workerCreatePaymentDocument)
  yield takeEvery(CREATE_PAYMENT_OPTIONAL_DOCUMENT, workerCreatePaymentOptionalDocument)
}

export function* workerFetchInvoices() {
  const {filters}: IInvoicesStorage = yield select((storage: StorageType) => storage.invoices)

  yield sagaUtils.workerWrapper(
    setIsLoading,
    fetchInvoices,
    filters,
    setInvoiceData,
    null,
    intl.formatMessage({id: 'SAGAS.GOODS.GROUPS.GET'}),
    'GET',
    'data',
    false,
    '',
    undefined,
    undefined
  )
}

export function* workerCreatePaymentDocument(action: any) {
  yield sagaUtils.workerWrapper(
    setIsRenewingDocument,
    createNewPaymentDocument,
    action.payload,
    setInvoiceCreateDocument,
    null,
    'Новый счет',
    'CREATE',
    'data',
    true,
    '',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (res: Blob) => {
      const url = window.URL.createObjectURL(res)
      return url
    }
  )
}

export function* workerPrintPaymentDocument(action: any) {
  yield sagaUtils.workerWrapper(
    setIsRenewingDocument,
    printNewPaymentDocument,
    action.payload,
    setInvoiceCreateDocument,
    null,
    'Новый счет',
    'CREATE',
    'data',
    true,
    '',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (res: Blob) => {
      const url = window.URL.createObjectURL(res)
      return url
    }
  )
}

export function* workerCreatePaymentOptionalDocument(action: any) {
  yield sagaUtils.workerWrapper(
    setIsRenewingDocument,
    createNewPaymentOptionalDocument,
    action.payload,
    setInvoiceCreateDocument,
    null,
    'Новый счет',
    'CREATE',
    'data',
    true,
    '',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    (res: Blob) => {
      const url = window.URL.createObjectURL(res)
      return url
    }
  )
}
