import {createAction} from '@reduxjs/toolkit'
import {FeedbacksActionTypes, IFeedbackRequestParams, IFeedbackSendParams} from './types'

export const FETCH_FEEDBACKS = FeedbacksActionTypes.FetchFeedbacks
export const FETCH_FETCH_FEEDBACKS_BY_ID = FeedbacksActionTypes.FetchFeedbackById
export const ASYNC_GENERATE_FEEDBACK = FeedbacksActionTypes.SET_Feedback

export const ASYNC_SEND_FEEDBACK = FeedbacksActionTypes.SEND_Feedback
export const FETCH_SETTINGS = FeedbacksActionTypes.FetchSettings

export const fetchFeedbacksAction = createAction(FETCH_FEEDBACKS)

export const fetchFeedbackByIdAction = createAction<number>(FETCH_FETCH_FEEDBACKS_BY_ID)

export const generateFeedbackCreator = createAction<IFeedbackRequestParams>(ASYNC_GENERATE_FEEDBACK)

export const sendFeedbackAction = createAction<IFeedbackSendParams>(ASYNC_SEND_FEEDBACK)

export const fetchFeedbacksSettingsAction = createAction(FETCH_SETTINGS)
