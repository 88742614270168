import {useEffect} from 'react'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

const sentryProdEnv = 'production'
const sentryDevEnv = 'development'

Sentry.init({
  dsn: 'https://1a575c0aba2a2feffe6ac0de893b3dc7@sentry.somebox.ru/15',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.browserTracingIntegration(),
  ],

  // устанавливает шанс отображения ошибки в Sentry (100% будет грузить систему)
  tracesSampleRate: 0.4,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV === 'production' ? sentryProdEnv : sentryDevEnv,
})
