import {createSelector} from '@reduxjs/toolkit'
import {StorageType} from '../../../setup'
import {CategorizedSubscriptionsType, SubscriptionCategory} from './types'
import {subscriptionsUtils} from '../../utils/dataUtils'

export const selectSelectedSubscriptions = (state: StorageType) =>
  state.subscriptions.selectedSubscriptions
export const selectIsLoading = (state: StorageType) => state.subscriptions.isLoading
export const selectIsUserSubscriptionsLoading = (state: StorageType) =>
  state.subscriptions.isUserSubscriptionsLoading
export const selectIsCreatingPayment = (state: StorageType) => state.subscriptions.isCreatingPayment
export const selectUserSubscriptions = (state: StorageType) => state.subscriptions.userSubscriptions
export const selectAllTariffs = (state: StorageType) => state.subscriptions.tariffs
export const selectActiveTariff = (state: StorageType) => state.subscriptions.selectedTariff
export const selectRedirectLink = (state: StorageType) => state.subscriptions.redirectLink
export const selectIsTopUpBalanceLoading = (state: StorageType) =>
  state.subscriptions.isTopupBalanceLoading

export const selectSuccessCreatingPayment = (state: StorageType) =>
  state.subscriptions.successCreatignPayment

export const selectIsTariffChanging = (state: StorageType) => state.subscriptions.isTariffChanging
export const selectIsRenewing = (state: StorageType) => state.subscriptions.isRenewing

export const selectSubscriptions = createSelector(
  (state: StorageType) => state.subscriptions.subscriptions,
  selectActiveTariff,
  (subscriptions: CategorizedSubscriptionsType, selectedTariff) => {
    return {
      [SubscriptionCategory.FAVORABLE]: subscriptionsUtils.getSubscriptionsByTariff(
        subscriptions[SubscriptionCategory.FAVORABLE],
        selectedTariff
      ),
      [SubscriptionCategory.SUBSCRIPTION]: subscriptionsUtils.getSubscriptionsByTariff(
        subscriptions[SubscriptionCategory.SUBSCRIPTION],
        selectedTariff
      ),
      [SubscriptionCategory.SERVICE]: subscriptionsUtils.getSubscriptionsByTariff(
        subscriptions[SubscriptionCategory.SERVICE],
        selectedTariff
      ),
    }
  }
)

export const selectInvoiceDocument = (state: StorageType) => state.subscriptions.invoiceDocument
