import {createAction} from '@reduxjs/toolkit'
import {
  subscriptionsActionTypes,
  IPaymentRequestParams,
  IBuySubscriptionRequestParams,
  IChangeTariffRequestParams,
  IRenewSubByInvoiceRequestParams,
} from './types'

export const FETCH_ALL_SUBSCRIPTIONS = subscriptionsActionTypes.GET_SUBSCRIPTIONS
export const FETCH_ALL_USER_SUBSCRIPTIONS = subscriptionsActionTypes.GET_USER_SUBSCRIPTIONS
export const AUTO_RENEW_SUBSCRIPTION = subscriptionsActionTypes.AUTO_RENEW__SUBSCRIPTION
export const CREATE_PAYMENT = subscriptionsActionTypes.CREATE_PAYMENT
export const TOP_UP_BALANCE = subscriptionsActionTypes.TOP_UP_BALANCE
export const BUY_SUBSCRIPTION = subscriptionsActionTypes.BUY_SUBSCRIPTION
export const CHANGE_TARIFF = subscriptionsActionTypes.CHANGE_TARIFF
export const RENEW_SUBSCRIPTION_BY_CARD = subscriptionsActionTypes.RENEW_SUBSCRIPTION_BY_CARD
export const RENEW_SUBSCRIPTION_BY_INVOICE = subscriptionsActionTypes.RENEW_SUBSCRIPTION_BY_INVOICE
export const RENEW_SUBSCRIPTION_BY_BALANCE = subscriptionsActionTypes.RENEW_SUBSCRIPTION_BY_BALANCE

export const fetchAllSubscriptionsAction = createAction(FETCH_ALL_SUBSCRIPTIONS)
export const fetchAllUserSubscriptionsAction = createAction(FETCH_ALL_USER_SUBSCRIPTIONS)
export const autoRenewSubscriptionAction = createAction<any>(AUTO_RENEW_SUBSCRIPTION)
export const createPaymentAction = createAction<IPaymentRequestParams>(CREATE_PAYMENT)
export const topUpBalanceAction = createAction<any>(TOP_UP_BALANCE)
export const buySubscriptionAction = createAction<IBuySubscriptionRequestParams>(BUY_SUBSCRIPTION)
export const changeTariffAction = createAction<IChangeTariffRequestParams>(CHANGE_TARIFF)
export const renewSubscriptionByCardAction = createAction<number>(RENEW_SUBSCRIPTION_BY_CARD)
export const renewSubscriptionByInvoiceAction = createAction<IRenewSubByInvoiceRequestParams>(
  RENEW_SUBSCRIPTION_BY_INVOICE
)
export const renewSubscriptionByBalanceAction = createAction<number>(RENEW_SUBSCRIPTION_BY_BALANCE)
