import { FC } from "react"


interface FieldHeaderProps {
    label: string
    description?: string
}

const FieldHeader: FC<FieldHeaderProps> = (props) => {
    return <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2 text-gray-700'>
        <span className='' style={{ fontWeight: 'bold' }}>
            {props.label}
        </span>
        {
            props.description
                ? <i className='fas fa-exclamation-circle ms-2 fs-7 cursor-pointer'
                    data-bs-toggle='tooltip'
                    title={props.description}
                ></i>
                : null
        }
    </label>
}


export default FieldHeader