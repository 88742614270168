/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Outlet, Route, Routes, Navigate, NavLink, useLocation} from 'react-router-dom'
import {Login} from './components/Login'
import NumberConfirm from './components/NumberConfirm'
import {Register} from './components/Register'

import {StorageType} from '../../../setup'
import {IUserModel} from './models/UserModel'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer'

const AuthLayout = () => {
  const location = useLocation()
  const srcVideo = '/media/video/authvideo.mp4'
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-row align-items-center justify-content-center h-100 bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column p-10 pb-lg-20'>
        {location.pathname !== '/auth/phone-confirm' && (
          <ul className='nav fs-3 mb-6 gap-2' style={{fontWeight: 'bold'}}>
            <li>
              <NavLink
                to='/auth/login'
                className={({isActive}) =>
                  isActive ? 'text-gray-700' : 'text-primary text-decoration-underline'
                }
              >
                Вход
              </NavLink>
            </li>
            /
            <li>
              <NavLink
                to='/auth/register'
                className={({isActive}) =>
                  isActive ? 'text-gray-700' : 'text-primary text-decoration-underline'
                }
              >
                Регистрация
              </NavLink>
            </li>
          </ul>
        )}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      <div className='d-flex flex-center flex-column w-lg-650px p-10 pb-lg-20'>
        <VideoPlayer
          options={{
            responsive: false,

            sources: [
              {
                src: srcVideo,
                type: 'video/mp4',
              },
            ],
          }}
        />
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => {
  const user: IUserModel = useSelector((state: StorageType) => state.auth.user, shallowEqual)

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='phone-confirm' element={<NumberConfirm />} />
        <Route
          index
          element={
            user && !user.params?.number_confirm ? (
              <Navigate to='/auth/phone-confirm' />
            ) : (
              <Navigate to='/auth/login' />
            )
          }
        />
      </Route>
    </Routes>
  )
}

export {AuthPage}
