import {apiSlice} from '../../../setup/api/apiSlice'

import {IGoodMPCard, IGoodTradeOffer} from './../../modules/goods/types'
import {DEFAULT_LIMIT, DEFAULT_PAGE} from './../../modules/goods/GoodsList'
import axios, {AxiosResponse} from 'axios'
import {
  IGood,
  IGoodCardsSaveRequest,
  IGoodGetParams,
  IGoodItemForm,
  IGoodResponse,
  IGoodsGetParams,
} from './types'
import {IGoodTradeOfferResponse} from '../../modules/goods/types'
import {IFilterParameters} from '../../components/lists/Filter'
import {PagedData} from '../types'
import {OFFERS_URL} from '../mpcards/api'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
export const GOODS_URL = `${API_URL}v2/goods/`
export const GOODS_OFFERS_URL = `${API_URL}v2/offers/`
export const GOODS_ATTRIBUTES_URL = `${API_URL}v2/`
export const GOODS_MPCARDS_URL = `${API_URL}v2/mpcards/`

export const GOODS_ATTRIBUTES_WB_URL = `${API_URL}v2/wb_content/ns/characteristics-configurator-api/content-configurator/api/` // wildberries attribute endpoint root
// v1/directory/countries?pattern=кит&lang=ru&top=1000
// v1/directory/brands?pattern=mar&lang=ru&top=1000

// =================  get good/goods =================
// #region

export const fetchGoods = (params: IGoodsGetParams) => {
  const page = params.page ?? DEFAULT_PAGE
  const items = params.items ?? DEFAULT_LIMIT
  const offset = page * items
  try {
    const res = axios.get(
      `${GOODS_URL}?offset=${offset}${items < 0 ? `` : `&limit=${items}`}&ordering=${
        params.order
      }&search=${params.search}${params.objectId ? `&subject_id=${params.objectId}` : ``}`
    )
    return res
  } catch {
    return null
  }
}

export const fetchGoodFromOreder = (uId: number) => {
  try {
    const res = axios.get(`${GOODS_URL}?offset=0&limit=10&ordering='id'&search=${uId}`)
    return res
  } catch {
    return null
  }
}

export const fetchGoodsForGroups = (params: IFilterParameters): Promise<AxiosResponse<any>> => {
  const page = params.pageNumber ?? DEFAULT_PAGE
  const items = params.itemsPerPage ?? DEFAULT_LIMIT
  const offset = page * items

  const res = axios.get(
    `${GOODS_URL}?offset=${offset}${params.itemsPerPage != -1 ? '&limit=' + items : ''}&ordering=${
      params.ordering
    }&search=${params.filter}`
  )
  return res
}

export const getGoodsForGroupsWithoutPagination = (query: string) => {
  return axios
    .get(`${GOODS_URL}?search=${query}`)
    .then((res) => res.data?.results ?? [])
    .catch(() => [])
}

export const fetchGood = (params: IGoodGetParams) => {
  try {
    const res = axios.get(`${GOODS_URL}${params.id}/?expand=parent`) //${params.expand != undefined && "/?=" + params.expand}
    return res
  } catch (error) {
    return null
  }
}
// #endregion

// =================  save good =================
// #region

// converts IGoodItemForm to IGoodSave
export const GoodItemFormToGoodSave = (params: IGoodItemForm) => {
  return {
    category: [params.category.id],
    customer_uid: params.customer_uid,
    id: params.id,
    images: params.images.map((i) => i.id),
    is_offer: params.is_offer,
    name: params.name,
    params: {
      card_data: params.card_data ?? undefined,
      wh_data: {
        depth: params.depth,
        height: params.height,
        weight: params.weight,
        width: params.width,
      },
      description: params.description ?? '',
    },
    parent: params?.parent?.id ? params.parent?.id : undefined,
  }
}

// saving good main info
export const saveGood = ({params, add = false}: {params: IGoodItemForm; add: boolean}) => {
  const payload = GoodItemFormToGoodSave(params)
  if (params?.parent?.id) payload.parent = params.parent?.id

  return add
    ? axios.post<IGoodResponse>(`${GOODS_URL}`, payload)
    : axios.put<IGoodResponse>(`${GOODS_URL}${params.id}/`, payload)
}
// #endregion

// =================  save good =================
// #region

// saving good main info
export const saveGoodCards = (request: IGoodCardsSaveRequest, add: boolean = false) => {
  // return add ? axios.post<IGoodResponse>(`${GOODS_URL}`, payload) : axios.put<IGoodResponse>(`${GOODS_URL}${params.id}/`, payload)
  return axios.post<boolean>(`${GOODS_URL}${request.goodId}/post_card_data/`, request.cards)
}
// #endregion

// =================  get attributes =================
// #region
// default (ozon) get attribute parameters
interface IGoodAttributeGetParams {
  attributeType: string
  categoryId: string[]
  language: string
}

// wildberries get list item attribute parameters
export interface IGoodAttributeWbListItemGetParams {
  pattern: string
  lang: string
}

// wildberries get attribute parameters
interface IGoodAttributeWbGetParams {
  name: string
  lang: string
}

// wildberries get attribute list parameters
export interface IGoodAttributeWbListItem {
  name: string
  parent: string
}

// wildberries get attribute data field
export interface IGoodAttributeWbData {
  addin: IGoodFieldWbAttribute[]
  name: string
  nomenclature: INomenclature
  parent: string
}

export interface INomenclature {
  addin?: IGoodFieldWbAttribute[]
  variation?: {addin?: IGoodFieldWbAttribute[]}
}

// default (ozon) attribute dynamic field types
let TGoodFieldAttributeType:
  | 'String'
  | 'Decimal'
  | 'Integer'
  | 'URL'
  | 'multiline'
  | 'Boolean'
  | 'ImageURL'
  | 'Choices'
export type IGoodFieldAttributeType = typeof TGoodFieldAttributeType

// default (ozon) attribute dynamic field
export interface IGoodFieldAttribute {
  description: string
  dictionary_id: number
  group_id: number
  group_name: string
  id: number
  is_collection: boolean
  is_required: boolean
  name: string
  type: IGoodFieldAttributeType
  items?: any //IGoodAttributeValueResponse[] | [] | IGoodAttributeValueResponse | null
  enabled?: boolean // needs for Trade Offer editing (we marked it as true when we needs to show dynamic field of this datatype)
  //isWb: boolean
}

// wildberries attribute dynamic field
export interface IGoodFieldWbAttribute {
  isAvailable: boolean
  required: boolean
  type: string
  useOnlyDictionaryValues: boolean

  dictionary?: string
  maxCount?: number
  isNumber?: boolean
  units?: string[]
  params?: IAddinParam[]
}

// default (ozon) attribute list of category
interface IGoodFieldAttributeResult {
  attributes: IGoodFieldAttribute[]
  category_id: number
}

// default (ozon) attribute response
export interface IGoodAttributeGetResponse {
  result: IGoodFieldAttributeResult[]
}

// wildberries attribute list items response
export interface IGoodAttributeGetWbListItemResponse {
  additionalErrors: null
  data: object
  error: boolean
  errorText: string
}

// wildberries attributes response
export interface IGoodAttributeGetWbResponse {
  additionalErrors: null
  data: IGoodAttributeWbData
  error: boolean
  errorText: string
}

// wildberries attributes response 2 (from subpaths)
export interface IGoodAttributeGetWbResponse2 {
  additionalErrors: null
  data: IGoodWbAttributeValueResponse[]
  error: boolean
  errorText: string
}

export const fetchAttributes = (params: IGoodAttributeGetParams) => {
  return axios.post<any, AxiosResponse<IGoodAttributeGetResponse>>(
    `${GOODS_ATTRIBUTES_URL}ozon/v3/category/attribute`,
    {
      attribute_type: params.attributeType,
      category_id: params.categoryId,
      language: params.language,
    }
  )
}

export const fetchWbAttributes = (params: IGoodAttributeWbGetParams) => {
  return axios.get<any, AxiosResponse<IGoodAttributeGetWbResponse>>(
    `${GOODS_ATTRIBUTES_WB_URL}v1/config/get/object/translated?name=${params.name}&lang=${params.lang}`
  )
}

// get wb attribute list items for good card tab wildberries
export const fetchWbAttributeListItems = (params: IGoodAttributeWbListItemGetParams) => {
  return axios.get<any, AxiosResponse<IGoodAttributeGetWbListItemResponse>>(
    `${GOODS_ATTRIBUTES_WB_URL}v1/config/get/object/list?pattern=${params.pattern}&lang=${params.lang}`
  )
}
//#endregion

// =================  get attribute values =================
// #region

export interface IGoodAttributeValueGetParams {
  attribute_id: number
  category_id: string
  language?: string
  last_value_id?: number
  limit?: number
  query?: string
}

export interface IGoodWbAttributeValueGetParams {
  lang?: string
  top?: number
  path: string
  pattern: string
  option?: string
}

export const EMPTY_IGOOD_ATTRIBUTE_VALUE_RESPONSE: IGoodAttributeValueResponse = {
  id: 0,
  info: '',
  picture: '',
  value: '',
  dictionary_value_id: null,
}

export interface IGoodAttributeValueResponse {
  id: number
  info: string
  picture: string
  value: string
  dictionary_value_id?: number | null
}

export interface IGoodWbAttributeValueResponse {
  key: string
  translate: string
}

export const fetchAttributeValues = ({
  attribute_id,
  category_id,
  query = '',
}: IGoodAttributeValueGetParams) => {
  return axios.post<any, AxiosResponse<IGoodAttributeValueResponse[]>>(
    `${GOODS_ATTRIBUTES_URL}ozon/v2/category/attribute/values`,
    {
      attribute_id: attribute_id,
      category_id: category_id,
      language: 'ru',
      last_value_id: 0,
      limit: 100,
      query: query,
    }
  )
}

// get wb attribute values
export const fetchWbAttributeValues = ({
  pattern,
  path,
  option,
  top = 1000,
  lang = 'ru',
}: IGoodWbAttributeValueGetParams) => {
  return axios.get<any, AxiosResponse<IGoodAttributeGetWbResponse2>>(
    `${GOODS_ATTRIBUTES_WB_URL}v1/directory${path}?pattern=${pattern}&option=${option}&lang=${
      lang ?? 'ru'
    }&top=${top ?? 1000}`
  )
}
// #endregion

// =================  save card data =================
// #region
// https://api-dev.shop-delivery.ru/goods/goods/28348/post_card_data/

export interface IGoodCardOzonSaveAttribute {
  attribute_id: number
  is_collection: boolean
  values?: IGoodAttributeValueResponse[] | IGoodAttributeValueResponse
}

export interface IGoodPostCardDataParams {
  category?: number[]
  id: number
  object?: 'wb_card' | 'ozon_card'
  payload: {
    attributes?:
      | {
          addin?: CardDataAddin[]
          countryProduction?: string
          object?: string
          supplierVendorCode?: string
        }
      | IGoodCardOzonSaveAttribute[]
    brand?: IBrandValue
    category_id?: string
  }
}

export interface IBrandValue {
  id: string
  selected_text: string
  text: string
}

export interface IAddinParam {
  dictionary_value_id?: number
  value: string
}

export interface CardDataAddin {
  type: string
  params: IAddinParam[]
}

export interface IAddinInAddin {
  addin: IGoodFieldWbAttribute[]
}

interface AnswerResult {
  success: boolean
  result: IGood
}

export const saveCardData = (good_id: number, payload: IGoodPostCardDataParams) => {
  return axios.post<any, AxiosResponse<AnswerResult>>(
    `${GOODS_URL}${good_id}/post_card_data/?expand=parent`,
    payload
  )
}

// =================   save universal mpcard template (from goods) =================
// http://api-dev.shop-delivery.ru/int_api/v2/goods/{id}/post_card_data/

//IGoodMpCardUniversal

// export const getUniversalMpCardUniversalAttributeValues = (params: IUniversalCategoriesParams) => {
//     return axios.post<any, AxiosResponse<AnswerResult>>(`${GOODS_URL}${good_id}/post_card_data/?expand=parent`, payload)
// }

// #endregion

// =================  get trade offers =================
// #region

//https://api-dev.shop-delivery.ru/int_api/v2/offers/mpcards/?good_id=29289

export interface IGoodTradeOffersGetParams {
  company?: number // company id
  good__name?: string // good name
  good_id?: number // good id
  good_uid?: number // article
  limit?: number // number of results
  marketplace?: string // code of marketplace
  mpcard_id?: number // card id on marketplace
  name?: string // name of offer
  offset?: number // index from which to return the results
  ordering?: string // Which field to use when ordering the results
  search?: string // search term
  sku_id?: string // article of offer
}

export const getGoodTradeOffers = (params: IGoodTradeOffersGetParams) => {
  return axios.get<any, AxiosResponse<IGoodTradeOfferResponse>>(
    `${GOODS_OFFERS_URL}mpcards/?good_id=${params?.good_id ?? ''}&company=${
      params?.company ?? ''
    }&good__name=${params?.good__name ?? ''}&good_uid=${params?.good_uid ?? ''}&limit=${
      params?.limit ?? ''
    }&marketplace=${params?.marketplace ?? ''}&mpcard_id=${params?.mpcard_id ?? ''}&name=${
      params?.name ?? ''
    }&offset=${params?.offset ?? ''}&ordering=${params?.ordering ?? ''}&search=${
      params?.search ?? ''
    }&sku_id=${params?.sku_id ?? ''}`
  )
  // return axios.get<any, AxiosResponse<IGoodTradeOfferResponse>>(`${GOODS_OFFERS_URL}mpcards/${params.good_id}/`)
}

// https://api-dev.shop-delivery.ru/goods/sku/34853/
// export const putGoodTradeOffer = (params: IGoodTradeOffersGetParams) => {
//     return axios.get<any, AxiosResponse<IGoodTradeOfferResponse>>(`${GOODS_OFFERS_URL}mpcards/?good_id=${params?.good_id ?? ''}&company=${params?.company ?? ''}&good__name=${params?.good__name ?? ''}&good_uid=${params?.good_uid ?? ''}&limit=${params?.limit ?? ''}&marketplace=${params?.marketplace ?? ''}&mpcard_id=${params?.mpcard_id ?? ''}&name=${params?.name ?? ''}&offset=${params?.offset ?? ''}&ordering=${params?.ordering ?? ''}&search=${params?.search ?? ''}&sku_id=${params?.sku_id ?? ''}`)
//     // return axios.get<any, AxiosResponse<IGoodTradeOfferResponse>>(`${GOODS_OFFERS_URL}mpcards/${params.good_id}/`)
// }
// #endregion

// =================  save trade offers card =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/offers/29289/post_card_data

export interface IGoodTradeOfferMarketplaceGetParams {
  offer_id: number // good id
  offerData: any
}

// save marketplace card of trade offer
export const saveGoodTradeOfferMarketplace = (params: IGoodTradeOfferMarketplaceGetParams) => {
  return axios.post<any, AxiosResponse<IGoodTradeOfferResponse>>(
    `${GOODS_OFFERS_URL}${params?.offer_id}/post_card_data/`,
    params.offerData
  )
}
// #endregion

// =================  save trade offers =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/offers/29289/post_card_data

export interface IGoodTradeOfferMarketplaceGetParams {
  offer_id: number // good id
  offerData: any
}

// save  trade offer
export const saveGoodTradeOffer = (params: IGoodTradeOfferMarketplaceGetParams) => {
  if (params.offer_id >= 0) {
    return axios.put<any, AxiosResponse<IGoodTradeOfferResponse>>(
      `${GOODS_OFFERS_URL}${params?.offer_id}/`,
      params.offerData
    )
  } else {
    const offerData = params.offerData
    delete offerData.id
    return axios.post<any, AxiosResponse<IGoodTradeOfferResponse>>(`${GOODS_OFFERS_URL}`, offerData)
  }
}

// #endregion

// =================  delete trade offers =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/offers/29289/

// delete trade offer
export const deleteGoodTradeOffers = (params: IGoodTradeOfferMarketplaceGetParams) => {
  return axios.delete(`${GOODS_OFFERS_URL}${params.offer_id}/`)
}

// #endregion

// =================  save price =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/prices

// {
//     "account": 2,
//     "params":{ "price_settings": {"old_price": 0, "premium_price": 0}},
//     "comment": "WEB",
//     "price": "50537.",
//     "price_type": "RETAIL",
//     "posted": true,
//     "date_posted": "2022-06-10T13:03:11.470Z",
//     "sku": 46007,
//     "mpcard": 44809
//   }

export interface IGoodTradeOfferPrice {
  account: number // 2, +
  params: {price_settings: {old_price: number; premium_price: number}} | null
  company?: string | null
  marketplace?: string | null
  comment: 'WEB'
  price: number //"50537.", +
  price_type: 'RETAIL'
  posted: false //true,
  sku: number //46007,
  mpcard?: number //44809  +
}

export interface IGoodTradeOfferPriceWithGoodIDANdFilter {
  account: number // 2, +
  params: {price_settings: {old_price: number; premium_price: number}} | null
  company?: string | null
  marketplace?: string | null
  comment: 'WEB'
  price: number //"50537.", +
  price_type: 'RETAIL'
  posted: false //true,
  sku: number //46007,
  mpcard?: number //44809  +
  goodId: number
  filter: string
}

// Александр Somebox, [15.06.2022 10:09]
// а так:
// account: number//обязательное,
//     params: object //{ "price_settings": {"old_price": 0, "premium_price": 0}} обязательное. Если зачеркнутой цены нет - ставим old_price=0,
//     comment: "WEB" - так и оставляем,
//     price: number //"50537.", обязательное,
//     price_type: "RETAIL", именно такое ставим,
//     posted: boolean //true, False всегда,
//     date_posted: string, не отправляем // "2022-06-10T13:03:11.470Z",
//     sku: number //46007, обязательное,
//     mpcard: number //44809, если у нас есть mpcard -то да, если карточка не создана, то нет

// save  trade offer price
export const saveTradeOfferPrice = (params: IGoodTradeOfferPrice) => {
  // return axios.post<any, AxiosResponse<IGoodTradeOfferPrice>>(`${GOODS_ATTRIBUTES_URL}prices/`, params)
  return axios.post<any, AxiosResponse<IGoodTradeOfferPrice>>(
    `${GOODS_ATTRIBUTES_URL}prices/post_price/`,
    params
  ) //v2
}

// #endregion

// =================  create trade offers card =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/offers/29289/post_card/

export interface IGoodTradeOfferCreateCardGetParams {
  mpcard_id: number // mpcard id
  offerid: number // offer id
  offerData: number // account id
}

export interface IGoodTradeOfferCreateCardResponse {
  success: boolean
  errors?: any
  result?: IGoodMPCard
}

// create  trade offer card
export const createGoodTradeOfferCard = (params: IGoodTradeOfferCreateCardGetParams) => {
  return axios.post<any, AxiosResponse<IGoodTradeOfferCreateCardResponse>>(
    `${GOODS_OFFERS_URL}${params?.mpcard_id}/post_card/`,
    {account: params.offerData}
  )
}

// #endregion

// =================   copy trade offers card =================
// #region
//https://api-dev.shop-delivery.ru/int_api/v2/mpcards/29289/clone_card/

// copy trade offer card
export const copyGoodTradeOfferCard = (params: IGoodTradeOfferCreateCardGetParams) => {
  return axios.post<any, AxiosResponse<IGoodTradeOfferCreateCardResponse>>(
    `${GOODS_MPCARDS_URL}${params?.mpcard_id}/clone_card/`,
    {account_id: params.offerData}
  )
}
// export const copyGoodTradeOfferCard = (params: IGoodTradeOfferCreateCardGetParams) => {
//     const res = axios.get<any, AxiosResponse<IGoodTradeOfferCreateCardResponse>>('http://localhost:3000/mpcards' )
//     console.log('account_id', params.offerData, 'res', res)
//     return res
// }

// #endregion

const goodsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGoods: builder.query<IGood[], IGoodsGetParams>({
      query: (params) => ({
        url: GOODS_URL,
        params,
      }),
      transformResponse: (response: any) => response.results,
    }),
    getTradeOffers: builder.query<IGoodTradeOffer[], IGoodTradeOffersGetParams>({
      query: (params) => ({
        url: GOODS_OFFERS_URL,
        params,
      }),
      transformResponse: (response: any) => response.results,
    }),
  }),
  overrideExisting: true,
})

export const {useGetGoodsQuery, useLazyGetGoodsQuery, useLazyGetTradeOffersQuery} = goodsApiSlice
