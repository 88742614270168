import axios from 'axios'
import {
  IUserSubscription,
  IPaymentRequestParams,
  IBuySubscriptionRequestParams,
  IChangeTariffRequestParams,
  IRenewSubByInvoiceRequestParams,
} from './types'

import {blobUtils} from '../../utils/dataUtils'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}v2/`

export const getAllSubscriptions = () => {
  return axios.get(`${API_URL}subscription_plans/`)
}

export const getUserSubscriptions = () => {
  return axios.get(`${API_URL}subscriptions/`, {params: {active: true}})
}

export const autoRenewSubscription = (data: IUserSubscription) => {
  return axios.post(`${API_URL}subscriptions/auto_renew_subscription/`, data)
}

export const createPayment = (data: IPaymentRequestParams) => {
  return axios.post(`${API_URL}invoices/create_payment/`, data)
}

export const topUpBalance = (amount: number) => {
  return axios.post(`${API_URL}invoices/deposit_balance/`, {amount})
}

export const buySubscriptionFromBalance = (data: IBuySubscriptionRequestParams) => {
  const {updatedCustomerInfo, ...rest} = data
  return axios.post(`${API_URL}invoices/buy_subscription_from_balance/`, rest)
}

export const changeTariff = (data: IChangeTariffRequestParams) => {
  const {updatedCustomerInfo, ...rest} = data
  return axios.post(`${API_URL}invoices/change_tariff/`, rest)
}

export const renewSubscriptionByCard = (subscription_id: number) => {
  return axios.post(`${API_URL}invoices/renew_subscription/`, {subscription_id})
}

export const renewSubscriptionByBalance = (subscription_id: number) => {
  return axios.post(`${API_URL}invoices/renew_subscription_from_balance/`, {subscription_id})
}

export const renewSubscriptionByInvoice = (data: IRenewSubByInvoiceRequestParams) => {
  return axios
    .post(
      `${API_URL}invoices/renew_subscription_from_e_invoice/`,
      {
        subscription_id: data.subscription_id,
        payer: data.payer,
      },
      {responseType: 'blob'}
    )
    .catch(async (response) => {
      const err = await blobUtils.readBlobAsText(response.response.data)
      const error: any = JSON.parse(err as string)
      // eslint-disable-next-line no-throw-literal
      throw {response: {data: error}}
    })
}
