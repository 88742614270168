import {apiSlice} from '../../../setup/api/apiSlice'
import axios, {AxiosResponse} from 'axios'
import {
  ICategory,
  IComparisonResponse,
  IComparisonSaved,
  IComparisonSaveRequest,
  IItemIdValue,
  IUniversalCategoriesParams,
} from './types'
import {IFilterParameters} from '../../components/lists/Filter'
import {GOODS_URL} from '../goods/api'
import {IWarehouseResponse} from '../warehouses/types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
//export const CATHEGORIES_URL = `${API_URL}cats/`
export const CATEGORIES_URL = `${API_URL}v2/categories/`
export const AUTOCOMPLETE_URL = `${API_URL}v2/autocomplete/categories`
export const UNIVERSAL_AUTOCOMPLETE_URL = `${API_URL}v2/autocomplete/cat_on_mp`
export const UNIVERSAL_CATEGORIES_URL = `${API_URL}v2/categories_on_mp/`
export const AUTOCOMPLETE_WAREHOUSES_URL = `${API_URL}v2/autocomplete/wb/warehouses`

export const fetchRootCategories = () => {
  return axios.get(CATEGORIES_URL)
}

export const fetchCategoriesById = (id: number) => {
  return axios.get(`${CATEGORIES_URL}?category_id=${id}`)
}

export const fetchCategoriesByFilter = (filter: string) => {
  return axios.get(`${AUTOCOMPLETE_URL}?q=${filter}`)
}

export const fetchCategoriesByFilterForGoodsGroups = (
  params: IFilterParameters
): Promise<AxiosResponse<any>> => {
  const res = axios.get(`${AUTOCOMPLETE_URL}?q=${params.filter}&marketplace=2`)
  return res
}

export const fetchCategoriesForGoodsGroups = (query: string) => {
  return axios
    .get(`${AUTOCOMPLETE_URL}?q=${query}&marketplace=2`)
    .then((res) => res.data?.results ?? [])
    .then((data) =>
      data.map((item: any) => ({
        id: item.id.id,
        text: item.text,
      }))
    )
    .catch(() => [])
}

export const fetchWarehousesForAcceptance = (query: string) => {
  return axios
    .get<IWarehouseResponse[]>(`${AUTOCOMPLETE_WAREHOUSES_URL}?query=${query}`)
    .then((res) => res?.data ?? [])
    .catch(() => [])
}

interface IUniversalCategoriesByFilterParams {
  filter: string
  marketplaceId: number
}
export const getUniversalCategoriesByFilter = (params: IUniversalCategoriesByFilterParams) => {
  return axios.get(
    `${UNIVERSAL_AUTOCOMPLETE_URL}?q=${params.filter}&marketplace=${params.marketplaceId}`
  )
}

interface IUniversalCategoriesResponse {
  categories?: ICategory[]
  success?: boolean
}
// gets universal categories by marketplace id and category id
export const getUniversalCategories = (params: IUniversalCategoriesParams) => {
  return axios.get<IUniversalCategoriesResponse>(
    `${UNIVERSAL_CATEGORIES_URL}?marketplace=${params.marketplaceId}${
      params.categoryId != undefined ? `&category_id=${params.categoryId}` : ''
    }`
  )
}

// gets categories attributes for category editor
export const getCategoriesForComparison = (catId: number) => {
  return axios.get<IComparisonResponse[]>(`${API_URL}v2/attributes/?category_id=${catId}`)
}

// gets categories attribute values for category editor
export const getCategoryAttributeValuesForComparison = (dictionary: string, query?: string) => {
  return axios.get<IItemIdValue[]>(
    `${process.env.REACT_APP_API_URL}${dictionary}${query?.length ? '&query=' + query : ''}`
  )
}

// get category comparisons from server
// get https://api-dev.shop-delivery.ru/int_api/v2/attributes_linking/?category_id=16137
export const loadComparisonFromServer = (params: number) => {
  return axios.get<IComparisonSaved>(`${API_URL}v2/attributes_linking/?mp_category_id=${params}`)
}

// save category comparisons
// post https://api-dev.shop-delivery.ru/int_api/v2/attributes_linking/
export const saveComparisonToServer = (params: IComparisonSaveRequest) => {
  return axios.post(`${API_URL}v2/attributes_linking/`, params)
}

const categoriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<ICategory[], string>({
      query: (q) => `${AUTOCOMPLETE_URL}_v2?query=${q}`,
    }),
  }),
  overrideExisting: true,
})

export const {useLazyGetCategoriesQuery} = categoriesApiSlice
