import {TTaskStateType, TTaskType} from './../store/tasks/types'
import _ from 'lodash'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {ISelectOption} from '../components/common/select/AsyncSelectInput'
import {ISelectExtendedOption} from '../modules/goods/list/FilterSelect'
import {IPaginationLink} from '../modules/goods/types'

import {
  CardDataAddin,
  IAddinParam,
  IGoodAttributeValueResponse,
  IGoodCardOzonSaveAttribute,
  IGoodFieldAttribute,
  IGoodFieldWbAttribute,
} from '../store/goods/api'
import {addPushMessageCreator} from '../store/system/actions'
import {AxiosResponse} from 'axios'
import {EMPTY_PUSH_MESSAGE, IPushMessage} from '../store/system/types'
import {intl} from '../modules/goods/item/validation'
import {call, put} from 'redux-saga/effects'
import {IUrlParameters} from '../components/lists/UrlFilteredList'
import {TAction} from '../../_metronic/i18n/Metronici18n'
import {IDateRange} from './types'
import moment from 'moment'
import {ISubscription, ITarif} from '../store/subscriptions/types'
import {TMarketplace} from '../store/mpWizard/types'
import {MarketPlaceCode, IMarketplace} from '../store/marketplaces/types'

let resultType: 'direct' | 'data' | 'data.result' | 'data.results' | 'data.categories'
export type TSagaResultType = typeof resultType
export interface IAdditionalPutActionWithParams {
  action: any
  params: any
}

export interface IAdditionalPutActionWithExtendedParams {
  action: any
  params?: any
  resultType?: TSagaResultType // from what object we get additional property
  resultTypePropertyName?: string | null // if we want to use results in params, we can set property name of data here, if ==null and params==null, then use direct data from answear to action in params
}

// gets data from promise to output object
export function getResultObject(oType: TSagaResultType, inputPromise: any) {
  switch (oType) {
    case 'direct':
      return inputPromise
    case 'data':
      return inputPromise?.data
    case 'data.result':
      return inputPromise?.data?.result
    case 'data.results':
      return inputPromise?.data?.results
    case 'data.categories':
      return inputPromise?.data?.categories
    default:
      return inputPromise
  }
}

export const filteringMarketplaces = (from: string, marketplaces: any) => {
  switch (from) {
    case 'feedback':
      return marketplaces.filter((m: any) =>
        [MarketPlaceCode.OZON, MarketPlaceCode.WB, MarketPlaceCode.YM].includes(m.code)
      )
    case 'acceptance-rates':
      return marketplaces.filter((m: any) => [MarketPlaceCode.WB].includes(m.code))
    default:
      return marketplaces.filter((m: any) =>
        [MarketPlaceCode.WB, MarketPlaceCode.OZON, MarketPlaceCode.YM].includes(m.code)
      )
  }
}

// utils for showing images
export const imgUtils = {
  // no photo
  no_photo: toAbsoluteUrl('/media/misc/image.png'), // app/store/system/types using it the same
  // set uncachable photos (add random parameter to it)
  setUncachableImages: (imageList: string[]) => {
    return imageList.map((i) => i + '?' + new Date().valueOf()) // add parameter because image may cahed and thumb will be old
  },
}

export const createLink: CREATE_LINK = {
  filterByCathegory: (id): string => {
    return `/goods/list/?cat=${id}`
  },

  toGood: (goodId: number | null = null): string => {
    return goodId != null ? `/goods/item/${goodId}` : `/goods/item/new`
  },

  toGoods: (): string => {
    return `/goods/list`
  },

  paginationLink: (urlParams: IUrlParameters): IPaginationLink => {
    const {pageNumber, itemsPerPage, ordering, filter, title, url} = urlParams
    //console.log('paginationLink',urlParams.additionalParameters)
    return {
      url: `${url}?page=${pageNumber}&ipp=${itemsPerPage}&order=${ordering ?? 'id'}&search=${
        filter ?? ''
      }${
        urlParams?.additionalParameters && urlParams.additionalParameters.length > 0
          ? urlParams.additionalParameters.reduce((acc: string, ap: ISelectExtendedOption) => {
              return acc + '&' + ap.label + '=' + ap.value
            }, '')
          : ''
      }`,
      title: title ?? '',
      num: pageNumber,
    }
  },
}

export const convertUtils = {
  addinParamToGoodAttributeValue: (param: IAddinParam): IGoodAttributeValueResponse => {
    return {value: param.value, id: 0, info: '', picture: '', dictionary_value_id: 0}
  },
  goodAttributeValueToAddinParam: (param: IGoodAttributeValueResponse): IAddinParam => {
    return {value: param.value, dictionary_value_id: param.dictionary_value_id ?? 0}
  },
  goodFieldAttributeToCardDataAddin: (param: IGoodFieldAttribute): CardDataAddin => {
    return {
      type: param.name,
      params:
        param && param.items
          ? Array.isArray(param.items)
            ? param?.items?.map((x) => convertUtils.goodAttributeValueToAddinParam(x))
            : [convertUtils.goodAttributeValueToAddinParam(param.items)]
          : [],
      //IGoodAttributeValueResponse[]
    }
  },
  goodFieldAttributeToGoodCardOzonSaveAttribute: (
    param: IGoodFieldAttribute
  ): IGoodCardOzonSaveAttribute => {
    return {
      attribute_id: param.id,
      is_collection: param.is_collection,
      values:
        param?.items != null && param?.items != undefined
          ? Array.isArray(param.items) && param.items.length
            ? (param.items as IGoodAttributeValueResponse[])
            : (param.items as IGoodAttributeValueResponse)
          : [],
    }
  },
  goodFieldWbAttributeToGoodAttributeValue: (param: IGoodFieldWbAttribute): IGoodFieldAttribute => {
    return {
      description:
        param.units && Array.isArray(param.units) && param.units.length > 0 && param.units[0]
          ? param.units[0]
          : '',
      dictionary_id: param.dictionary ? 1 : 0,
      group_id: param.useOnlyDictionaryValues ? 1 : 0, //  means that here can/can't be custom values (not from dictionary)
      group_name: param.dictionary ?? '', // path to dictionary values
      id: param.maxCount ? -param.maxCount : 0, // contains max items to add to multiselect, negative because when we saving, this attributes will be different then with real id. we can check, if id is negative, then it's not id.
      // is_collection: (param.maxCount && param.maxCount > 1) ? true : false,  // it worked before 29.06.22, but not validate in yup
      is_collection: param.dictionary && param.dictionary.length > 0 ? true : false,
      is_required: param.required,
      name: param.type,
      type: param.isNumber ? 'Decimal' : 'String',
      //isWb: true,
      items: param?.params?.map((p) => convertUtils.addinParamToGoodAttributeValue(p)) ?? null, //attr.dictionary ? [] : EMPTY_IGOOD_ATTRIBUTE_VALUE_RESPONSE,
    }
  },
  goodAttributeValueToGoodFieldWbAttribute: (param: IGoodFieldAttribute): IGoodFieldWbAttribute => {
    return {
      isAvailable: true,
      required: param.is_required,
      type: param.name,
      useOnlyDictionaryValues: param.group_id ? true : false,
      dictionary: param.group_name,
      isNumber: param.type == 'Decimal',
      maxCount: param.id < 0 ? -param.id : 0,
      params:
        param && param.items
          ? Array.isArray(param.items)
            ? (param.items as IGoodAttributeValueResponse[])?.map((item) => {
                return convertUtils.goodAttributeValueToAddinParam(item)
              })
            : [convertUtils.goodAttributeValueToAddinParam(param.items)]
          : [],
      units: [],
    }
  },
  taskStateToString: (taskState: TTaskStateType) => {
    switch (taskState) {
      case 'FAILURE':
        return {color: 'red', name: 'Завершено с ошибкой'}
      case 'PENDING':
        return {color: 'pink', name: 'Создано'}
      case 'RECEIVED':
        return {color: 'lightgreen', name: 'Готово к работе'}
      case 'RETRY':
        return {color: 'orange', name: 'Повторный запуск'}
      case 'REVOKED':
        return {color: 'red', name: 'Аннулировано'}
      case 'STARTED':
        return {color: 'blue', name: 'Запущено'}
      case 'SUCCESS':
        return {color: 'green', name: 'Выполнено успешно'}
      default:
        return {color: 'white', name: ''}
    }
  },
  typeImportToString: (typeImport: TTaskType) => {
    switch (typeImport) {
      case 'cards':
        return 'Карточки'
      case 'prices':
        return 'Цены'

      default:
        return ''
    }
  },
  dateTimeToFormatString: (dt?: Date | string | null) => {
    if (dt && new Date(dt)) {
      const d = new Date(dt)

      return {
        date: d.getDate(),
        month: d.getMonth() + 1,
        year: d.getFullYear(),
        hour: d.getHours(),
        minute: d.getMinutes(),
        second: d.getSeconds(),
        formatDate: `${d.getDate() <= 9 ? '0' + d.getDate().toString() : d.getDate().toString()}.${
          d.getMonth() + 1 <= 9
            ? '0' + (d.getMonth() + 1).toString()
            : (d.getMonth() + 1).toString()
        }.${d.getFullYear()} в ${
          d.getHours() <= 9 ? '0' + d.getHours().toString() : d.getHours().toString()
        }:${d.getMinutes() <= 9 ? '0' + d.getMinutes().toString() : d.getMinutes().toString()}:${
          d.getSeconds() <= 9 ? '0' + d.getSeconds().toString() : d.getSeconds().toString()
        }`,
      }
    }
  },

  // mpCardExtendedToGoodTradeOffer: (param: IMPCardExtended): IGoodTradeOffer => {
  //     param.
  //     return {
  //         barcodes: [],
  //         customer_uid: "",
  //         good: 0,
  //         id: 0,
  //         images: [],
  //         mpcards: [],
  //         name: "",
  //         params: null,
  //         stock: [],
  //     }
  // }
  stringToHashSumNumber: (str: string) => {
    let strArr = str.split(''),
      strTemp = 0
    for (let i = 0; i < strArr.length; i++) {
      strTemp += strArr[i].charCodeAt(0)
    }
    return strTemp
  },
}

export const objectUtils = {
  deepCopy_old: (source: any) => {
    return JSON.parse(JSON.stringify(source))
  },
  deepCopy: (source: any) => {
    return _.cloneDeep(source)
  },
  deepCopyModern: (source: any) => {
    return structuredClone(source)
  },
  deepEqual: (x: any, y: any) => {
    if (_.isEqual(x, y)) return true
    else return false
  },
  deepEqual2: (x: any, y: any) => {
    if (x === y) {
      return true
    } else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
      if (Object.keys(x).length != Object.keys(y).length) return false

      for (var prop in x) {
        if (y.hasOwnProperty(prop)) {
          if (!objectUtils.deepEqual2(x[prop], y[prop])) return false
        } else return false
      }

      return true
    } else return false
  },
  /**
   * Flattens a nested object into a single-level object by concatenating the keys with a dot separator.
   *
   * @param {any} obj - The object to be flattened.
   * @return {any} The flattened object.
   */
  flattenObject: (obj: any): any => {
    const result: any = {}
    function recurse(object: any, prefix: string = '') {
      for (let key in object) {
        let pre = prefix.length ? prefix + '.' : prefix
        if (object.hasOwnProperty(key)) {
          let newKey = pre + key
          if (typeof object[key] === 'object' && object[key] !== null) {
            Object.assign(result, recurse(object[key], newKey))
          } else {
            result[newKey] = object[key]
          }
        }
      }
      return result
    }
    return recurse(obj)
  },
}

// utils for showing messages/questions
export const messageUtils = {
  // confirm with additional data
  question: (text: string, action: any, data?: any) => {
    return window.confirm(text) ? (data ? action(data) : action()) : false
  },
  questionNoAction: (text: string) => {
    return window.confirm(text) ? true : false
  },
  questionWindowTitleAndAction: (action: any, title: string) => {
    return {action, title}
  },
}

// set of functions (generators) to async work with redux sagas
export const sagaUtils = {
  // universal wrapper for standart saga
  workerWrapper: function* (
    setIsLoadingAction: any, // function-reducer for isLoading (sets flag isLoading before (true) and after (false) body of operations), [!] if type of this property is Array, then action won't work in finally block
    apiAction: any, // main action (api-request to server)
    apiParams: any, // parameters to main action (if null, then main action pushes without parameters apiAction())
    reducerPutAction: any, // main put action to reducer
    reducerParams: any = null, // parameters of main reducer (if null, then gets object from response)
    localizationName: string, // name of operation to show baloon
    actionName: TAction, // type of action to select make messasge response in baloon
    resultType: TSagaResultType, // type of result (object structure, where main response data will be)
    showSuccess: boolean = true, // show baloon if success operation
    successDetails: string = '', // text in success message in baloon
    additionalPreActions?: IAdditionalPutActionWithParams | IAdditionalPutActionWithParams[], // action or set of actoins with parameters to push it before main saga actions
    additionalSuccessActions?:
      | IAdditionalPutActionWithExtendedParams
      | IAdditionalPutActionWithExtendedParams[], // action or set of actoins with parameters to push it after main saga actions when success
    additionalFailActions?:
      | IAdditionalPutActionWithExtendedParams
      | IAdditionalPutActionWithExtendedParams[], // action or set of actoins with parameters to push it after main saga actions when fails
    additionalErrorActions?: IAdditionalPutActionWithParams | IAdditionalPutActionWithParams[], // action or set of actoins with parameters to push it after main saga actions when catch error
    additionalAfterActions?: IAdditionalPutActionWithParams | IAdditionalPutActionWithParams[], // action or set of actoins with parameters to push custom data after getting data from server and setting in by reducers
    actionWithResultDataAndSetToStorageWithReducer?: (res: any) => any // here we can make action with res and result of it will be stored to put action in reducer
  ): any {
    let resultOfFunction: any = null
    try {
      // check and push pre actions
      if (additionalPreActions) {
        Array.isArray(additionalPreActions) && additionalPreActions?.length
          ? additionalPreActions.map(function* (apa) {
              apa.params ? yield put(apa.action(apa.params)) : yield put(apa.action())
            })
          : (additionalPreActions as IAdditionalPutActionWithParams).params
          ? yield put(
              (additionalPreActions as IAdditionalPutActionWithParams).action(
                (additionalPreActions as IAdditionalPutActionWithParams).params
              )
            )
          : yield put((additionalPreActions as IAdditionalPutActionWithParams).action())
      }

      // check and push put isloading action
      // setIsLoadingAction && (yield put(setIsLoadingAction(true)))
      setIsLoadingAction &&
        (Array.isArray(setIsLoadingAction)
          ? setIsLoadingAction.length > 0 && (yield put(setIsLoadingAction[0](true)))
          : yield put(setIsLoadingAction(true)))

      // push main server-api action
      const promise =
        apiAction != null
          ? yield call(apiParams ? () => apiAction(apiParams) : () => apiAction())
          : null

      // gets result from server in selected type of object data
      let res = promise != null ? getResultObject(resultType, promise) : null

      // make changes to original res
      if (actionWithResultDataAndSetToStorageWithReducer) {
        res = actionWithResultDataAndSetToStorageWithReducer(res)
      }

      // if data was responded
      if (res && promise?.status && promise.status < 400) {
        // check and push additional success actions
        if (additionalSuccessActions) {
          if (Array.isArray(additionalSuccessActions) && additionalSuccessActions?.length) {
            additionalSuccessActions.map(function* (asa) {
              console.log('asa', asa)
              // let res_suc = asa?.resultType && asa?.resultTypePropertyName?.length ? (getResultObject(asa?.resultType, promise)) : null
              let res_suc = asa?.resultType ? getResultObject(asa?.resultType, promise) : null

              asa &&
                (asa.params
                  ? yield put(asa.action(asa.params))
                  : // : (asa.resultTypePropertyName?.length
                  asa.resultTypePropertyName !== undefined
                  ? asa.resultTypePropertyName?.length
                    ? yield put(asa.action(res_suc[asa.resultTypePropertyName]))
                    : yield put(asa.action(res_suc))
                  : yield put(asa.action()))
            })
          } else {
            const asa = additionalSuccessActions as IAdditionalPutActionWithExtendedParams
            let res_suc = asa?.resultType ? getResultObject(asa?.resultType, promise) : null

            // asa
            //     && (asa.params
            //         ? (yield put(asa.action(asa.params)))
            //         : (asa.resultTypePropertyName !== undefined
            //             ? (asa.resultTypePropertyName?.length
            //                 ? yield put(asa.action(res_suc[asa.resultTypePropertyName]))
            //                 : yield put(asa.action(res_suc)))
            //             : yield put(asa.action())))
            if (asa)
              if (asa.params) {
                yield put(asa.action(asa.params))
              } else {
                if (asa.resultTypePropertyName !== undefined) {
                  if (asa.resultTypePropertyName?.length) {
                    yield put(asa.action(res_suc[asa.resultTypePropertyName]))
                  } else {
                    yield put(asa.action(res_suc))
                  }
                } else {
                  yield put(asa.action())
                }
              }
          }
        }

        // check and push main reducer action
        reducerPutAction && (yield put(reducerPutAction(reducerParams ? reducerParams : res)))

        // push task to show success baloon
        showSuccess &&
          (yield put(
            addPushMessageCreator({
              name: localizationName,
              status: 'success',
              message: intl.formatMessage({id: `SAGAS.${actionName}.SUCCESS`}),
              details: successDetails,
            })
          ))

        resultOfFunction = res
      } else {
        // chack and push additional fail actions
        if (additionalFailActions) {
          if (Array.isArray(additionalFailActions) && additionalFailActions?.length) {
            additionalFailActions.map(function* (afa) {
              // let res_fail = afa?.resultType && afa?.resultTypePropertyName?.length ? (getResultObject(afa?.resultType, promise)) : null
              let res_fail = afa?.resultType ? getResultObject(afa?.resultType, promise) : null

              afa &&
                (afa.params
                  ? yield put(afa.action(afa.params))
                  : // : (afa.resultTypePropertyName?.length
                  //     ? yield put(afa.action(res_fail[afa.resultTypePropertyName]))
                  //     : yield put(afa.action())))
                  afa.resultTypePropertyName !== undefined
                  ? afa.resultTypePropertyName?.length
                    ? yield put(afa.action(res_fail[afa.resultTypePropertyName]))
                    : yield put(afa.action(res_fail))
                  : yield put(afa.action()))
            })
          } else {
            const afa = additionalFailActions as IAdditionalPutActionWithExtendedParams
            // let res_fail = afa?.resultType && afa?.resultTypePropertyName?.length ? (getResultObject(afa?.resultType, promise)) : null
            let res_fail = afa?.resultType ? getResultObject(afa?.resultType, promise) : null

            afa &&
              (afa.params
                ? yield put(afa.action(afa.params))
                : afa.resultTypePropertyName !== undefined
                ? afa.resultTypePropertyName?.length
                  ? yield put(afa.action(res_fail[afa.resultTypePropertyName]))
                  : yield put(afa.action(res_fail))
                : yield put(afa.action()))
          }
        }

        // push task to show fail baloon (if == null, then serverAction is null)
        if (res != null) {
          yield put(
            addPushMessageCreator({
              name: localizationName,
              status: 'error',
              message: intl.formatMessage({id: `SAGAS.${actionName}.FAIL`}),
              details: errorUtils.getErrorFromResponse(promise),
            })
          )
        }
      }

      // check and push after actions
      if (additionalAfterActions) {
        Array.isArray(additionalAfterActions) && additionalAfterActions?.length
          ? additionalAfterActions.map(function* (apa) {
              apa.params ? yield put(apa.action(apa.params)) : yield put(apa.action())
            })
          : (additionalAfterActions as IAdditionalPutActionWithParams).params
          ? yield put(
              (additionalAfterActions as IAdditionalPutActionWithParams).action(
                (additionalAfterActions as IAdditionalPutActionWithParams).params
              )
            )
          : yield put((additionalAfterActions as IAdditionalPutActionWithParams).action())
      }
    } catch (error: any) {
      // check and push additional error actions
      if (additionalErrorActions) {
        if (Array.isArray(additionalErrorActions) && additionalErrorActions?.length) {
          additionalErrorActions.map(function* (aea) {
            aea && (aea.params ? yield put(aea.action(aea.params)) : yield put(aea.action()))
          })
        } else {
          const aea = additionalErrorActions as IAdditionalPutActionWithParams
          aea && (aea.params ? yield put(aea.action(aea.params)) : yield put(aea.action()))
        }
      }

      // show error baloon
      yield put(
        addPushMessageCreator({
          name: localizationName,
          status: 'error',
          message: intl.formatMessage({id: `SAGAS.${actionName}.FAIL`}),
          details: errorUtils.getErrorFromObject(error) ?? [
            intl.formatMessage({id: 'SAGAS.UNKNOWNERROR'}),
          ],
        })
      )
    } finally {
      // disables isLoading flag
      // setIsLoadingAction && (yield put(setIsLoadingAction(false)))

      // if parameter is aarray, then don't call action to off loading parameter
      // need for cascade callings
      setIsLoadingAction &&
        !Array.isArray(setIsLoadingAction) &&
        (yield put(setIsLoadingAction(false)))

      // return server data
      return resultOfFunction
    }
  },
  // combined request to reducer with server response and client's data in common object
  workerWrapperCombinedReducerRequest: function* (
    setIsLoadingAction: any, // function-reducer for isLoading (sets flag isLoading before (true) and after (false) body of operations), [!] if type of this property is Array, then action won't work in finally block
    apiAction: any, // main action (api-request to server)
    apiParams: any, // parameters to main action (if null, then main action pushes without parameters apiAction())
    reducerPutAction: any, // main put action to reducer
    reducerParams: any = null, // parameters of main reducer (if null, then gets object from response)
    combinedReducerData: any,
    localizationName: string, // name of operation to show baloon
    actionName: TAction, // type of action to select make messasge response in baloon
    resultType: TSagaResultType, // type of result (object structure, where main response data will be)
    showSuccess: boolean = true, // show baloon if success operation
    successDetails: string = '', // text in success message in baloon
    additionalPreActions?: IAdditionalPutActionWithParams | IAdditionalPutActionWithParams[], // action or set of actoins with parameters to push it before main saga actions
    additionalSuccessActions?:
      | IAdditionalPutActionWithExtendedParams
      | IAdditionalPutActionWithExtendedParams[], // action or set of actoins with parameters to push it after main saga actions when success
    additionalFailActions?:
      | IAdditionalPutActionWithExtendedParams
      | IAdditionalPutActionWithExtendedParams[], // action or set of actoins with parameters to push it after main saga actions when fails
    additionalErrorActions?: IAdditionalPutActionWithParams | IAdditionalPutActionWithParams[], // action or set of actoins with parameters to push it after main saga actions when catch error
    additionalAfterActions?: IAdditionalPutActionWithParams | IAdditionalPutActionWithParams[], // action or set of actoins with parameters to push custom data after getting data from server and setting in by reducers
    actionWithResultDataAndSetToStorageWithReducer?: (res: any) => any // here we can make action with res and result of it will be stored to put action in reducer
  ): any {
    let resultOfFunction: any = null
    try {
      // check and push pre actions
      if (additionalPreActions) {
        Array.isArray(additionalPreActions) && additionalPreActions?.length
          ? additionalPreActions.map(function* (apa) {
              apa.params ? yield put(apa.action(apa.params)) : yield put(apa.action())
            })
          : (additionalPreActions as IAdditionalPutActionWithParams).params
          ? yield put(
              (additionalPreActions as IAdditionalPutActionWithParams).action(
                (additionalPreActions as IAdditionalPutActionWithParams).params
              )
            )
          : yield put((additionalPreActions as IAdditionalPutActionWithParams).action())
      }

      // check and push put isloading action
      // setIsLoadingAction && (yield put(setIsLoadingAction(true)))
      setIsLoadingAction &&
        (Array.isArray(setIsLoadingAction)
          ? setIsLoadingAction.length > 0 && (yield put(setIsLoadingAction[0](true)))
          : yield put(setIsLoadingAction(true)))

      // push main server-api action
      const promise =
        apiAction != null
          ? yield call(apiParams ? () => apiAction(apiParams) : () => apiAction())
          : null

      // gets result from server in selected type of object data
      let res = promise != null ? getResultObject(resultType, promise) : null

      // make changes to original res
      if (actionWithResultDataAndSetToStorageWithReducer) {
        res = actionWithResultDataAndSetToStorageWithReducer(res)
      }

      // if data was responded
      if (res && promise?.status && promise.status < 400) {
        // check and push additional success actions
        if (additionalSuccessActions) {
          if (Array.isArray(additionalSuccessActions) && additionalSuccessActions?.length) {
            additionalSuccessActions.map(function* (asa) {
              // let res_suc = asa?.resultType && asa?.resultTypePropertyName?.length ? (getResultObject(asa?.resultType, promise)) : null
              let res_suc = asa?.resultType ? getResultObject(asa?.resultType, promise) : null

              asa &&
                (asa.params
                  ? yield put(asa.action(asa.params))
                  : asa.resultTypePropertyName !== undefined
                  ? asa.resultTypePropertyName?.length
                    ? yield put(asa.action(res_suc[asa.resultTypePropertyName]))
                    : yield put(asa.action(res_suc))
                  : yield put(asa.action()))
            })
          } else {
            const asa = additionalSuccessActions as IAdditionalPutActionWithExtendedParams
            // let res_suc = asa?.resultType && asa?.resultTypePropertyName?.length ? (getResultObject(asa?.resultType, promise)) : null
            let res_suc = asa?.resultType ? getResultObject(asa?.resultType, promise) : null

            asa &&
              (asa.params
                ? yield put(asa.action(asa.params))
                : asa.resultTypePropertyName !== undefined
                ? asa.resultTypePropertyName?.length
                  ? yield put(asa.action(res_suc[asa.resultTypePropertyName]))
                  : yield put(asa.action(res_suc))
                : yield put(asa.action()))
          }
        }

        // check and push main reducer action
        reducerPutAction &&
          (yield put(
            reducerPutAction(
              reducerParams
                ? {main: reducerParams, combined: combinedReducerData}
                : {main: res, combined: combinedReducerData}
            )
          ))

        // push task to show success baloon
        showSuccess &&
          (yield put(
            addPushMessageCreator({
              name: localizationName,
              status: 'success',
              message: intl.formatMessage({id: `SAGAS.${actionName}.SUCCESS`}),
              details: successDetails,
            })
          ))

        resultOfFunction = res
      } else {
        // chack and push additional fail actions
        if (additionalFailActions) {
          if (Array.isArray(additionalFailActions) && additionalFailActions?.length) {
            additionalFailActions.map(function* (afa) {
              // let res_fail = afa?.resultType && afa?.resultTypePropertyName?.length ? (getResultObject(afa?.resultType, promise)) : null
              let res_fail = afa?.resultType ? getResultObject(afa?.resultType, promise) : null

              afa &&
                (afa.params
                  ? yield put(afa.action(afa.params))
                  : afa.resultTypePropertyName !== undefined
                  ? afa.resultTypePropertyName?.length
                    ? yield put(afa.action(res_fail[afa.resultTypePropertyName]))
                    : yield put(afa.action(res_fail))
                  : yield put(afa.action()))
            })
          } else {
            const afa = additionalFailActions as IAdditionalPutActionWithExtendedParams
            let res_fail = afa?.resultType ? getResultObject(afa?.resultType, promise) : null

            afa &&
              (afa.params
                ? yield put(afa.action(afa.params))
                : afa.resultTypePropertyName !== undefined
                ? afa.resultTypePropertyName?.length
                  ? yield put(afa.action(res_fail[afa.resultTypePropertyName]))
                  : yield put(afa.action(res_fail))
                : yield put(afa.action()))
          }
        }

        // push task to show fail baloon (if == null, then serverAction is null)
        if (res != null) {
          yield put(
            addPushMessageCreator({
              name: localizationName,
              status: 'error',
              message: intl.formatMessage({id: `SAGAS.${actionName}.FAIL`}),
              details: errorUtils.getErrorFromResponse(promise),
            })
          )
        }
      }

      // check and push after actions
      if (additionalAfterActions) {
        Array.isArray(additionalAfterActions) && additionalAfterActions?.length
          ? additionalAfterActions.map(function* (apa) {
              apa.params ? yield put(apa.action(apa.params)) : yield put(apa.action())
            })
          : (additionalAfterActions as IAdditionalPutActionWithParams).params
          ? yield put(
              (additionalAfterActions as IAdditionalPutActionWithParams).action(
                (additionalAfterActions as IAdditionalPutActionWithParams).params
              )
            )
          : yield put((additionalAfterActions as IAdditionalPutActionWithParams).action())
      }
    } catch (error: any) {
      // check and push additional error actions
      if (additionalErrorActions) {
        if (Array.isArray(additionalErrorActions) && additionalErrorActions?.length) {
          additionalErrorActions.map(function* (aea) {
            aea && (aea.params ? yield put(aea.action(aea.params)) : yield put(aea.action()))
          })
        } else {
          const aea = additionalErrorActions as IAdditionalPutActionWithParams
          aea && (aea.params ? yield put(aea.action(aea.params)) : yield put(aea.action()))
        }
      }

      // show error baloon
      yield put(
        addPushMessageCreator({
          name: localizationName,
          status: 'error',
          message: intl.formatMessage({id: `SAGAS.${actionName}.FAIL`}),
          details: errorUtils.getErrorFromObject(error) ?? [
            intl.formatMessage({id: 'SAGAS.UNKNOWNERROR'}),
          ],
        })
      )
    } finally {
      // disables isLoading flag
      // setIsLoadingAction && (yield put(setIsLoadingAction(false)))
      setIsLoadingAction &&
        !Array.isArray(setIsLoadingAction) &&
        (yield put(setIsLoadingAction(false)))

      // return server data
      return resultOfFunction
    }
  },
}

export const blobUtils = {
  readBlobAsText: (blob: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsText(blob)
    })
  },
}

export const errorUtils = {
  getErrorFromResponse: (response: AxiosResponse): string[] => {
    let errors = [intl.formatMessage({id: 'UTILS.ERRORS.UNKNOWNERROR'})]
    try {
      if (response && response.data && response.status != undefined && response.status >= 400) {
        let responseData: any = response.data
        if (Array.isArray(responseData)) {
          errors = responseData
        } else if (response.data.errors && Array.isArray(response.data.errors)) {
          errors = response.data.errors
        } else {
          errors = Object.entries(responseData)?.map(
            (x) => `${intl.formatMessage({id: 'UTILS.ERRORS.FIELD'})} ${x[0]}: ${x[1]}`
          ) ?? [intl.formatMessage({id: 'UTILS.ERRORS.UNKNOWNERROR'})]
        }
      } else {
        // here we can work with errors from catch block (catch (error <--))
        const rsp: any = response
        const resp = rsp.response

        if (resp && resp.data && resp.status != undefined && resp.status >= 400) {
          let respData: any = resp.data
          if (Array.isArray(respData)) {
            errors = respData
          } else if (resp.data.errors && Array.isArray(resp.data.errors)) {
            errors = resp.data.errors
          } else {
            errors = Object.entries(respData)?.map(
              (x) => `${intl.formatMessage({id: 'UTILS.ERRORS.FIELD'})} ${x[0]}: ${x[1]}`
            ) ?? [intl.formatMessage({id: 'UTILS.ERRORS.UNKNOWNERROR'})]
          }
        }
      }
    } catch {
      errors = [intl.formatMessage({id: 'UTILS.ERRORS.UNKNOWNERROR'})]
    }

    return errors
  },
  getErrorFromObject: (error: any) => {
    return error?.response?.data?.errors?.length
      ? error.response.data.errors.map(
          (p: any) =>
            `${typeof p == 'string' ? '' : intl.formatMessage({id: 'UTILS.ERRORS.FIELD'})} ${
              typeof p == 'string' ? p : `${Object.entries(p)[0]}: ${Object.entries(p)[1]}`
            }`
        )
      : error?.response?.data?.errors
      ? Object.entries(error.response.data.errors)?.map(
          (x) => `${intl.formatMessage({id: 'UTILS.ERRORS.FIELD'})} ${x[0]}: ${x[1]}`
        )
      : error?.response?.data
      ? Array.isArray(error.response.data)
        ? error.response.data
        : Object.entries(error.response.data)?.map(
            (x) => `${intl.formatMessage({id: 'UTILS.ERRORS.FIELD'})} ${x[0]}: ${x[1]}`
          )
      : [intl.formatMessage({id: 'UTILS.ERRORS.UNKNOWNERROR'})]
  },
  addErrorFromResponse: (response: AxiosResponse) => {
    let message: IPushMessage = EMPTY_PUSH_MESSAGE
    try {
      if (response && response.data && response.status != undefined && response.status >= 400) {
        // 1. {field1: ["message1", "message2", ...], field2: ["message"]}
        // 2. ["message1", "message2", ...]

        let responseData: any = response.data
        if (Array.isArray(responseData)) {
          message.status = 'error'
          message.name = intl.formatMessage({id: 'UTILS.ERRORS.ERROR'})
          message.message = intl.formatMessage({id: 'UTILS.ERRORS.ERROROCCURRED'})
          message.details = responseData
        } else {
          message.status = 'error'
          message.name = intl.formatMessage({id: 'UTILS.ERRORS.ERROR'})
          message.message = intl.formatMessage({id: 'UTILS.ERRORS.ERROROCCURRED'})
          message.details = Object.entries(responseData)?.map(
            (x) => `${intl.formatMessage({id: 'UTILS.ERRORS.FIELD'})} ${x[0]}: ${x[1]}`
          ) ?? [intl.formatMessage({id: 'UTILS.ERRORS.UNKNOWNERROR'})]
        }
      }
    } catch {
      message.status = 'error'
      message.name = intl.formatMessage({id: 'UTILS.ERRORS.ERROR'})
      message.message = intl.formatMessage({id: 'UTILS.ERRORS.ERROROCCURRED'})
      message.details = [intl.formatMessage({id: 'UTILS.ERRORS.UNKNOWNERROR'})]
    }

    return addPushMessageCreator(message)
  },
  addErrorFromObject: (error: any) => {
    let message: IPushMessage = EMPTY_PUSH_MESSAGE
    // try{
    message.status = 'error'
    message.name = intl.formatMessage({id: 'UTILS.ERRORS.ERROR'})
    message.message = intl.formatMessage({id: 'UTILS.ERRORS.ERROROCCURRED'})
    message.details = error?.response?.data
      ? Array.isArray(error.response.data)
        ? error.response.data
        : Object.entries(error.response.data)?.map(
            (x) => `${intl.formatMessage({id: 'UTILS.ERRORS.FIELD'})} ${x[0]}: ${x[1]}`
          )
      : [intl.formatMessage({id: 'UTILS.ERRORS.UNKNOWNERROR'})]

    return addPushMessageCreator(message)
  },
  addSuccessFromString: (msg: string) => {
    let message: IPushMessage = EMPTY_PUSH_MESSAGE
    try {
      message.status = 'success'
      message.name = intl.formatMessage({id: 'UTILS.ERRORS.SUCCESS'})
      message.message = ''
      message.details = [msg]
    } catch {
      message.status = 'success'
      message.name = intl.formatMessage({id: 'UTILS.ERRORS.SUCCESS'})
      message.message = ''
      message.details = [msg]
    }

    return addPushMessageCreator(message)
  },
}

// set of functions to format date and time
export const dateUtils = {
  standartDateString(date: Date, separator?: string) {
    return `${date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()}${separator ?? '.'}${
      date.getMonth() <= 8 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    }${separator ?? '.'}${date.getFullYear()}`
  },
  extendDateString(date: Date) {
    return date.toLocaleDateString('ru-RU', {day: 'numeric', month: 'long', year: 'numeric'})
  },
  dateDiff(date: Date) {
    const currentDate = new Date()

    const differenceTime = Math.floor((Number(date) - Number(currentDate)) / (1000 * 60 * 60 * 24))
    if (differenceTime <= 0) return '0 дней'
    let daysText = 'дней'
    if (differenceTime % 100 < 11 || differenceTime % 100 > 14) {
      const lastDigit = differenceTime % 10
      if (lastDigit === 1) {
        daysText = 'день'
      } else if (lastDigit >= 2 && lastDigit <= 4) {
        daysText = 'дня'
      }
    }

    return differenceTime + ' ' + daysText
  },
  standartTimeString(date: Date, separator?: string) {
    return `${date.getHours() <= 9 ? '0' + date.getHours() : date.getHours()}${separator ?? ':'}${
      date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()
    }${separator ?? ':'}${date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds()}`
  },
  standartDateTimeString(
    date: Date,
    dateSeparator?: string,
    timeSeparator?: string,
    separatorBetweenDateAndTime?: string
  ) {
    return `${dateUtils.standartDateString(date, dateSeparator)} ${
      separatorBetweenDateAndTime ?? ' '
    } ${dateUtils.standartTimeString(date, timeSeparator)}`
  },
  // from string to IDateRange
  convertStringToIDateRange(after: string, before: string, defaultDate: IDateRange): IDateRange {
    let res: IDateRange | null

    try {
      res = {
        startDate: moment(after).isValid() ? moment(after) : defaultDate.startDate,
        endDate: moment(before).isValid() ? moment(before) : defaultDate.endDate,
      }
    } catch {
      res = defaultDate
    }

    return res
  },
  // range 1 equals range 2
  dateRangeIsEqual(range1: IDateRange, range2: IDateRange): boolean {
    return range1.startDate == range2.startDate && range1.endDate == range2.endDate
  },
}

export const subscriptionsUtils = {
  getSubscriptionsByTariff(subscriptions: ISubscription[], tariff: ITarif): ISubscription[] {
    return (
      subscriptions?.map((s) => ({
        ...s,
        tariffs: s.tariffs.filter((t) => t.duration === tariff.duration),
      })) || []
    )
  },
}

export const customDeliveryTimeUtils = {
  /**
   * Transforms the custom delivery time to the server format.
   *
   * @param {any} originalData - the original data to be transformed
   * @return {{[key: string]: number[]}} the transformed data in server format
   */
  transformCustomDeliveryTimeToServer(originalData: any): {[key: string]: number[]} {
    const transformedData: {[key: string]: number[]} = {}

    originalData.forEach(
      (item: {dayStart: number; dayEnd: number; region: {id: string; label: string}}) => {
        const {
          dayStart,
          dayEnd,
          region: {id: regionId},
        } = item
        if (!transformedData[regionId]) {
          transformedData[regionId] = []
        }

        transformedData[regionId].push(dayStart, dayEnd)
      }
    )

    return transformedData
  },

  /**
   * Transforms the custom delivery time data received from the server.
   *
   * @param {any} originalData - the original data received from the server
   * @return {Array} an array of transformed custom delivery time data
   */
  // TODO: перенести типы в другое место
  transformCustomDeliveryTimeFromServer(originalData: any): {
    dayStart: number
    dayEnd: number
    region: {
      id: string
      label: string
    }
  }[] {
    const transformedData: {
      dayStart: number
      dayEnd: number
      region: {
        id: string
        label: string
      }
    }[] = []

    originalData.forEach((obj: {id: string; label: string; res: number[]}) => {
      const [dayStart, dayEnd] = obj.res
      transformedData.push({
        dayStart,
        dayEnd,
        region: {id: obj.id, label: obj.label},
      })
    })

    return transformedData
  },
}

interface CREATE_LINK {
  filterByCathegory: (id: number) => {}
  toGood: (goodId?: number | null, fromList?: boolean) => {}
  toGoods: () => {}
  paginationLink: (par: IUrlParameters, addPar?: ISelectOption[]) => any
}
