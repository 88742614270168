import {useState, ChangeEvent, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {updateData} from '../../../../../store'
import {testConnectionAction} from '../../../../../store/mpWizard/actions'
import {useIntl} from 'react-intl'

import {Form, Button} from 'react-bootstrap'

import {IWizardState, EnumStatuses} from '../../../../../store/mpWizard/types'
import {StorageType} from '../../../../../../setup'

type TTokens = {
  apiId: string
  apiKey: string
}

const OzonStep1 = () => {
  const {marketplace, isLoading, currentStep, data}: IWizardState = useSelector(
    (storage: StorageType) => storage.mpWizard
  )

  const intl = useIntl()

  const connectionStatus = data[`step${currentStep}`]?.status

  const initDataTokens = {
    apiId: '',
    apiKey: '',
  }

  const [tokens, setTokens] = useState<TTokens>(data?.step0 || initDataTokens)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!data?.step0) {
      dispatch(updateData({key: 'step0', field: 'apiId', value: ''}))
      dispatch(updateData({key: 'step0', field: 'apiKey', value: ''}))
    }
  }, [])

  const updateTokens = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target

    dispatch(updateData({key: 'step0', field: name, value: value}))

    setTokens((prev) => ({...prev, [name]: value}))
  }

  const onTestConnection = (apiKey: string, apiId: string) => () => {
    dispatch(updateData({key: `step${currentStep}`, field: 'status', value: EnumStatuses.WAITING}))

    dispatch(
      testConnectionAction({
        marketplaceId: marketplace?.id || -1,
        connectionParams: [
          {parameterName: 'client_id', value: apiId || ''},
          {parameterName: 'api_key', value: apiKey || ''},
        ],
      })
    )
  }

  return (
    <div className='fs-5'>
      <p className='mb-7'>
        Токен авторизации необходим для того, чтобы наш сервис мог взаимодействовать с вашим
        аккаунтом OZON и выполнять необходимые задачи, такие как автоматизация процессов, интеграция
        с вашим магазином и управление данными.
      </p>

      <div className='mb-5'>
        <Form.Label className='text-gray-700' htmlFor='apiId'>
          API ID:
        </Form.Label>
        <Form.Control
          name='apiId'
          id='apiId'
          value={tokens.apiId}
          onChange={updateTokens}
          className='bg-secondary mb-6'
        />

        <Form.Label className='text-gray-700' htmlFor='apiKey'>
          API KEY:
        </Form.Label>
        <Form.Control
          id='apiKey'
          name='apiKey'
          value={tokens.apiKey}
          onChange={updateTokens}
          className='bg-secondary'
        />
      </div>

      <div className='mb-9 w-100 d-flex align-items-center justify-content-between'>
        {connectionStatus === EnumStatuses.SUCCESS && (
          <p className='text-success mb-0'>
            {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.CONNECTION.SUCCESS'})}
          </p>
        )}

        {connectionStatus === EnumStatuses.ERROR && (
          <p className='text-danger mb-0'>
            {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.CONNECTION.FAILURE'})}
          </p>
        )}

        <Button
          className='text-nowrap d-block ms-auto'
          disabled={tokens.apiId === '' || tokens.apiKey === '' || isLoading}
          onClick={onTestConnection(tokens.apiKey, tokens.apiId)}
        >
          {isLoading
            ? intl.formatMessage({id: 'ACTION.WAIT'})
            : intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.CONNECTION.CHECK'})}
        </Button>
      </div>
      <p>
        Чтобы получить API KEY, необходимо перейти в личный кабинет на{' '}
        <a href='https://seller.ozon.ru/' target='_blank' rel='noopener noreferrer'>
          портале поставщика
        </a>{' '}
        OZON.
      </p>
      <p>
        Кабинет поставщика &gt; API ключи &gt; Seller API &gt; сгенерировать ключ &gt; в разделе
        «Тип токена» ввести название токена &gt; Выбрать тип Администратор &gt; Создать ключ.
      </p>
      <p>API ID - Client ID в личном кабинете пользователя</p>
      <p>
        Более подробно можно ознакомиться в{' '}
        <a
          href='https://docs.shop-delivery.ru/personal_account/#_8'
          target='_blank'
          rel='noopener noreferrer'
        >
          Руководстве пользователя
        </a>
        .
      </p>
    </div>
  )
}

export default OzonStep1
