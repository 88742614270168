import {FC} from 'react'
import clsx from 'clsx'

interface ErrorMessageProps {
  message: string
  className?: string
}

const ErrorMessage: FC<ErrorMessageProps> = ({message, className}) => {
  return <p className={clsx('text-danger', className)}>{message}</p>
}

export default ErrorMessage
