import {useSelector, shallowEqual, useDispatch} from 'react-redux'
import {updateData} from '../../../../../store'
import {addCustomerCompanyAction} from '../../../../../store/mpWizard/actions'

import LinkingToLegalEntity from '../../LinkingToLegalEntity'

import {StorageType} from '../../../../../../setup'
import {IWizardState, TCompany} from '../../../../../store/mpWizard/types'

const WBStep4 = () => {
  const {currentStep, data}: IWizardState = useSelector(
    (state: StorageType) => state.mpWizard,
    shallowEqual
  )

  const autocompleteCCFromStep2: TCompany | null = data?.step2?.selectedWBCompany
  const сustomerCompany: TCompany | null = data?.[`step${currentStep}`]?.customerCompany?.value
  const action = data?.[`step${currentStep}`]?.customerCompany?.action

  const dispatch = useDispatch()

  const onSelectCustomerCompany = (cc: TCompany | null) => {
    dispatch(
      updateData({
        key: `step${currentStep}`,
        field: 'customerCompany',
        value: {value: cc, action: 'select'},
      })
    )
  }

  const onCancelSelection = () => {
    dispatch(
      updateData({
        key: `step${currentStep}`,
        field: 'customerCompany',
        value: null,
      })
    )
  }

  const onAddCustomerCompany = (cc: TCompany) => {
    dispatch(addCustomerCompanyAction({company: cc}))
  }

  return (
    <div>
      <p className='mb-8 fs-5 text-gray-700'>
        Для правильного функционирования сервиса нам необходимо привязать личный кабинет к
        юридическому лицу, которое им владеет. Вы можете выбрать его из имеющихся или создать новое.
      </p>
      <LinkingToLegalEntity
        autocompleteCC={autocompleteCCFromStep2}
        onCancelSelection={onCancelSelection}
        selectedCC={сustomerCompany}
        action={action}
        onSelectCustomerCompany={onSelectCustomerCompany}
        addCustomerCompanyHandler={onAddCustomerCompany}
      />
    </div>
  )
}

export default WBStep4
