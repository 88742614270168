import {useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateData} from '../../../../../store'
import {StorageType} from '../../../../../../setup'

import {Form} from 'react-bootstrap'

const WBStep5 = () => {
  const data = useSelector((storage: StorageType) => storage.mpWizard.data)
  const currentStep = useSelector((storage: StorageType) => storage.mpWizard.currentStep)

  const [accName, setAccName] = useState(data?.[`step${currentStep}`]?.accountName || '')

  const dispatch = useDispatch()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAccName(e.target.value)

    dispatch(updateData({key: `step${currentStep}`, field: 'accountName', value: e.target.value}))
  }

  return (
    <div className='fs-5'>
      <p className='mb-9'>
        Пожалуйста, введите название вашего кабинета. Это поможет вам легко идентифицировать и
        управлять своими аккаунтами в будущем. Название может быть любым, удобным для вас, и оно
        будет использоваться только в рамках нашего сервиса.
      </p>

      <Form.Control
        name='authorization'
        value={accName}
        onChange={onChange}
        className='bg-secondary mb-9'
        placeholder='Название кабинета'
      />
    </div>
  )
}

export default WBStep5
