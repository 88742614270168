import {isString} from '@sentry/utils'
import {CSSProperties, DetailedHTMLProps, FC, HTMLAttributes, useState} from 'react'
import {Button, Col, Row, Toast, ToastContainer} from 'react-bootstrap'
import {string} from 'yup/lib/locale'
import {IPushMessage} from '../../../../../store/system/types'

interface NotifyProps {
  message: IPushMessage | null
  show: boolean
  close: any
  numberOf: number
  // onClick?: (data: any) => Promise<boolean | undefined>
  // path?: string // needed for using KSVG
  className?: string
  // fieldWidth?: number
  // signWidth?: number
}

const PushMessageNotify: FC<NotifyProps> = (props) => {
  const showStyle: CSSProperties = {
    position: 'fixed',
    bottom: '60px',
    width: '300px',
    zIndex: '999',
    height: '150px',
    left: '5px',
  }
  const hideStyle: CSSProperties = {
    position: 'fixed',
    bottom: '60px',
    width: '1px',
    zIndex: '-1',
    height: '1px',
    left: '1px',
  }
  //const [show, setShow] = useState(false);

  return (
    <div style={props.show ? showStyle : hideStyle}>
      <ToastContainer position='bottom-center' className='p-3'>
        <Toast
          bg={
            props.message?.status == 'success'
              ? 'success'
              : props.message?.status == 'info'
              ? 'info'
              : 'warning'
          }
          // onClose={() => setShow(false)}
          onClose={() => props.close()}
          key={props.message?.id}
          show={props.show}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <img src='holder.js/20x20?text=%20' className='rounded me-2' alt='' />
            {/* <strong className="me-auto">{header1}</strong> */}
            <strong className='me-auto'>{props?.message?.name ?? ''}</strong>
            {/* <small>{header2}</small> */}
          </Toast.Header>
          {/* <Toast.Body>{success ? successMessage : errorMessage}</Toast.Body> */}
          <Toast.Body>
            <h4>{props?.message?.message ?? ''}</h4>
            {props?.message?.details && Array.isArray(props?.message?.details) ? (
              <ul>
                {props.message.details.map((d, t) => (
                  <li key={t}>
                    {isString(d) ? d : d.message && isString(d.message) ? d.message : ''}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{props?.message?.details ?? ''}</p>
            )}

            <div className='d-flex flex-row justify-content-center align-items-end'>
              <Button variant='light' size='sm' onClick={() => props.close()}>
                OK ({props.numberOf})
              </Button>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  )
}

export default PushMessageNotify
