import {createSlice} from '@reduxjs/toolkit'
import {emptyState as initialState} from './types'

const invoicesSlice = createSlice({
  name: 'invoicesSlice',
  initialState,
  reducers: {
    setInvoiceData(state, action) {
      state.items = action.payload.results
      state.count = action.payload.count
      state.next = action.payload.next
      state.previous = action.payload.previous
      state.isLoading = false
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload
    },

    setInvoiceDataFilters(state, action) {
      state.filters = action.payload
    },

    setInvoiceCreateDocument(state, action) {
      state.invoiceDocument = action.payload
    },

    setIsRenewingDocument(state, action) {
      state.isRenewing = action.payload
    },
    
  },
})

export const {
  setInvoiceData,
  setIsLoading,
  setInvoiceDataFilters,
  setInvoiceCreateDocument,
  setIsRenewingDocument,
} = invoicesSlice.actions

export const invoicesReducer = invoicesSlice.reducer
