import React, {useState} from 'react'
import 'react-bootstrap-typeahead/css/Typeahead.min.css'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import {LabelKey} from 'react-bootstrap-typeahead/types/types'
import {intl} from '../../modules/goods/item/validation'
import {AxiosPromise} from 'axios'

import './SelectInput.css'

import './SelectInput.css'

const SEARCH_URI = 'https://api.github.com/search/users'
const filterBy = () => true
type SelectInputProps<V, T> = {
  title: string
  fetchData: (query: string) => Promise<T[]>
  labelKey: LabelKey
  setValue: (nextValue: V[]) => void
  valueKey?: (option: T) => V
  selected?: T[]
  isErrorMassege?: boolean
  multiple?: boolean
}

const SelectInput = <V, T extends Record<string, any>>(props: SelectInputProps<V, T>) => {
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<T[]>([])

  const handleSearch = (query: string) => {
    if (isLoading) return
    setIsLoading(true)
    props
      .fetchData(query)
      .then((items) => {
        items && setOptions(items)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div style={{marginBottom: '20px'}}>
      <div
        className='d-flex align-items-center fs-6  form-label mb-2 text-gray-700'
        style={{fontWeight: 'bold'}}
      >
        {props.title}
      </div>
      <AsyncTypeahead
        className='w-100  mb-5  border border-gray-500 border-1 rounded'
        onChange={(selected) => {
          const nextSelected = props.valueKey ? (selected as T[]).map(props.valueKey) : selected
          props.setValue(nextSelected as V[])
        }}
        emptyLabel='Ничего не найдено'
        multiple={Boolean(props.multiple)}
        filterBy={filterBy}
        id='async-example'
        isLoading={isLoading}
        selected={props.selected}
        labelKey={props.labelKey}
        minLength={2}
        onSearch={handleSearch}
        options={options}
        placeholder={intl.formatMessage({
          id: 'COMPONENTS.TABLES.GOODS.LIST.FILTER.PLACEHOLDER',
        })}
      />
      {props.isErrorMassege && (
        <div className='text-danger fs-6'>
          {intl.formatMessage({id: 'ANSWERGENERATE.VALIDATION'})}
        </div>
      )}
    </div>
  )
}

export {SelectInput}
