import {useRef, useEffect, FC} from 'react'
import videojs from 'video.js'

import 'video.js/dist/video-js.css'

type PlayerOptions = typeof videojs.options

type Player = typeof videojs.players

interface IVideoPlayerProps {
  options?: PlayerOptions
}

const initialOptions: PlayerOptions = {
  controls: true,
  fluid: true,
  responsive: true,
  autoplay: false,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
}

const VideoPlayer: FC<IVideoPlayerProps> = ({options}) => {
  const videoNode = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<Player>(null)

  useEffect(() => {
    if (videoNode.current) {
      playerRef.current = videojs(videoNode.current, {
        ...initialOptions,
        ...options,
      }).ready(function () {
        // console.log('onPlayerReady', this);
      })
      return () => {
        if (playerRef.current) {
          playerRef.current.dispose()
        }
      }
    }
  }, [options, videoNode])

  return <video ref={videoNode} className='video-js' />
}

export default VideoPlayer
