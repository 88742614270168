import {FC, useState, useRef, useEffect} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {autocompleteCompany} from '../../../../store/customerCompanys/api'

import ReactSelect from 'react-select'
import {Button} from 'react-bootstrap'
import {SelectInput} from '../../../../components/inputs/SelectInput'
import LegalEntityInfo from './LegalEntityInfo/LegalEntityInfo'

import {StorageType} from '../../../../../setup'
import {IWizardState} from '../../../../store/mpWizard/types'
import {ICustomerCompanyState, ICustomerCompany} from '../../../../store/customerCompanys/types'
import {TCompany} from '../../../../store/mpWizard/types'

import styles from './LinkingToLegalEntity.module.scss'

interface ILinkingToLegalEntityProps {
  selectedCC?: TCompany | null
  autocompleteCC?: TCompany | null //для ВБ
  action?: string
  onSelectCustomerCompany: (cc: TCompany | null) => void
  addCustomerCompanyHandler: (cc: TCompany) => void
  onCancelSelection: () => void
}

const getSelectedCCByAction =
  (action: string, currentCC?: TCompany | null, currentAction?: string) => () =>
    currentAction === action ? currentCC ?? null : null

const LinkingToLegalEntity: FC<ILinkingToLegalEntityProps> = ({
  selectedCC,
  autocompleteCC,
  action,
  onSelectCustomerCompany,
  addCustomerCompanyHandler,
  onCancelSelection,
}) => {
  const [selectedCustomerCompany, setSelectedCustomerCompany] = useState<TCompany | null>(
    getSelectedCCByAction('select', selectedCC, action)
  )
  const [autocompleteCustomerCompany, setAutocompleteCustomerCompany] = useState<TCompany | null>(
    autocompleteCC ? autocompleteCC : getSelectedCCByAction('create', selectedCC, action)
  )

  const blocks = useRef<HTMLDivElement[]>([])

  const {items: customerCompanys, isLoading: isLoadingCustomerCompanys}: ICustomerCompanyState =
    useSelector((s: StorageType) => s.customerCompanys, shallowEqual)

  const {isLoading: isAddingCustomerCompany}: IWizardState = useSelector(
    (s: StorageType) => s.mpWizard
  )

  const customerId: number = useSelector((s: StorageType) => s.auth.user.customer.id)

  const intl = useIntl()

  useEffect(() => {
    if (action) {
      blocks.current[action === 'create' ? 1 : 0].classList.add(styles.active)
    }
  }, [])

  const onSetCustomerCompany = (cc: TCompany | null) => {
    blocks.current[1].classList.remove(styles.active)
    blocks.current[0].classList.add(styles.active)

    onSelectCustomerCompany(cc)
  }

  const onAddCustomerCompany = (cc: TCompany | null) => {
    if (!cc) return

    blocks.current[0].classList.remove(styles.active)
    blocks.current[1].classList.add(styles.active)

    addCustomerCompanyHandler({...cc, customer: customerId})
  }

  const onCancelSelectedCompany = () => {
    blocks.current[0].classList.remove(styles.active)
    setSelectedCustomerCompany(null)
    onCancelSelection()
  }

  return (
    <div className='text-gray-700'>
      <div className='d-flex align-items-center justify-content-around px-8'>
        <h6 className='m-0 text-gray-700'>
          {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.COMPANY.SELECT'})}
        </h6>
        <span className='fs-5 ms-11'>или</span>
        <h6 className='m-0 text-gray-700'>
          {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.COMPANY.CREATE'})}
        </h6>
      </div>
      <div className='d-flex align-items-start'>
        <div className='border-end pe-1 w-50'>
          <div
            ref={(el) => {
              if (el) {
                blocks.current[0] = el
              }
            }}
            className={clsx('p-8 rounded', styles.customerWrapper)}
          >
            <div className='mb-9 border-bottom border-gray-400' style={{paddingBottom: '20px'}}>
              <div
                className='d-flex align-items-center fs-5 form-label mb-3 text-gray-700'
                style={{fontWeight: 'bold'}}
              >
                {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.ORGANIZATION.SELECT'})}:
              </div>
              <ReactSelect
                isSearchable={false}
                className='my-1'
                isMulti={false}
                isLoading={isLoadingCustomerCompanys}
                onChange={(selected: any) => {
                  setSelectedCustomerCompany(selected ? selected.value : null)
                }}
                placeholder={intl.formatMessage({
                  id: 'COMPONENTS.ORDERS.LIST.FILTERS.CUSTOMER.PLACEHOLDER',
                })}
                loadingMessage={() =>
                  intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.ALL.LOADING'})
                }
                noOptionsMessage={() =>
                  intl.formatMessage({id: 'COMPONENTS.ORDERS.LIST.FILTERS.CUSTOMER.NOVALUES'})
                }
                getOptionLabel={(w: any) => w.label}
                isClearable={false}
                options={customerCompanys?.map((mp) => ({label: mp.name, value: mp})) ?? []}
                value={
                  selectedCustomerCompany
                    ? ({
                        label: selectedCustomerCompany.name,
                        value: selectedCustomerCompany,
                      } as any)
                    : null
                }
              />
            </div>
            <LegalEntityInfo customerCompany={selectedCustomerCompany} />
            <div className='d-flex gap-5 align-items-center justify-content-center'>
              <Button
                disabled={!selectedCustomerCompany || isLoadingCustomerCompanys}
                className='px-20'
                onClick={() => onSetCustomerCompany(selectedCustomerCompany)}
              >
                {intl.formatMessage({id: 'ACTION.PICK'})}
              </Button>
              {getSelectedCCByAction('select', selectedCC, action)() !== null && (
                <Button
                  disabled={isAddingCustomerCompany}
                  className='px-20'
                  onClick={onCancelSelectedCompany}
                >
                  Отменить выбор
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className='w-50 ps-1'>
          <div
            ref={(el) => {
              if (el) {
                blocks.current[1] = el
              }
            }}
            className={clsx('p-8 rounded', styles.customerWrapper)}
          >
            <div className='mb-9'>
              <SelectInput<{id: number; name: string}, ICustomerCompany>
                selected={(autocompleteCustomerCompany ? [autocompleteCustomerCompany] : []).map(
                  (x) =>
                    ({
                      id: x,
                      name: `${x.name} (${x?.inn}, ${x?.address})`,
                    } as unknown as ICustomerCompany)
                )}
                multiple={false}
                labelKey={(option: any) => `${option.name} (${option?.inn}, ${option?.address})`}
                fetchData={autocompleteCompany}
                title='Введите наименование организации (не менее 4 символов):'
                setValue={(nextValue) => {
                  setAutocompleteCustomerCompany(nextValue[0] as TCompany)
                }}
              />
            </div>
            <LegalEntityInfo customerCompany={autocompleteCustomerCompany} />
            <Button
              disabled={!autocompleteCustomerCompany || isAddingCustomerCompany}
              className='px-20 mx-auto d-block'
              onClick={() => onAddCustomerCompany(autocompleteCustomerCompany)}
            >
              {intl.formatMessage({id: 'ACTION.CREATE'})}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkingToLegalEntity
