export interface ISignedFieldProps {
  Field: any
  Sign: any
  className?: string
  children?: any
  signMarginEnd?: string // need if we have another icon/block under sign placeholder
}

const SignedField = (props: ISignedFieldProps) => {
  return (
    <div className={`d-flex align-items-center ${props.className ? props.className : 'w-100'}`}>
      <div className={`d-flex position-relative w-100`}>
        {props.Field}
        {props.signMarginEnd ? (
          <div
            className='position-absolute translate-middle-y top-50 end-0'
            style={{marginRight: props.signMarginEnd}}
          >
            {props.Sign && props.Sign}
          </div>
        ) : (
          <div className='position-absolute translate-middle-y top-50 end-0 me-2 ml-3'>
            {props.Sign && props.Sign}
          </div>
        )}
      </div>
      {props.children && props.children}
    </div>
  )
}

export default SignedField
