import {useState, useEffect, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {updateData} from '../../../../../store'
import {addCustomerCompanyAction} from '../../../../../store/mpWizard/actions'

import LinkingToLegalEntity from '../../LinkingToLegalEntity'

import {TYMAccount, TCompany, TCompanyInfo} from '../../../../../store/mpWizard/types'
import {StorageType} from '../../../../../../setup'

const YMStep3 = () => {
  const currentStep = useSelector((state: StorageType) => state.mpWizard.currentStep)
  const data = useSelector((state: StorageType) => state.mpWizard.data)

  const selectedYMAccounts: TYMAccount[] = data?.step1?.selectedYMAccounts
  const currentCustomerCompanys = useMemo(() => data?.[`step${currentStep}`] ?? {}, [data])
  console.log('currentCustomerCompanys', currentCustomerCompanys)

  const initCompanysInfo = selectedYMAccounts.map((acc) => ({
    accId: acc.id,
    customerCompany: null,
  }))

  const [companysInfo, setCompanysInfo] = useState<TCompanyInfo[]>(initCompanysInfo)

  const dispatch = useDispatch()

  // проверка на наличия в глобальном состоянии новой компании и добавление в локальный стейт
  useEffect(() => {
    Object.keys(currentCustomerCompanys).forEach((accId) => {
      if (
        currentCustomerCompanys[accId] &&
        currentCustomerCompanys[accId].action === 'create' &&
        !companysInfo.find((ci) => ci.accId === +accId)?.customerCompany
      ) {
        onChangeCompanysInfo(+accId, currentCustomerCompanys[accId].value)
      }
    })
  }, [currentCustomerCompanys])

  // если в глобальном стейте нет компании, то задаем начальное значение
  useEffect(() => {
    if (Object.values(currentCustomerCompanys).length === 0) {
      selectedYMAccounts.forEach((acc) => {
        dispatch(updateData({key: 'step2', field: `${acc.id}`, value: null}))
      })
    }
  }, [])

  // если всем аккаунтам установлен компания, то задаем глобальное состояние
  useEffect(() => {
    if (companysInfo.every((ci) => Boolean(ci.customerCompany))) {
      companysInfo.forEach((cI) => {
        if (!currentCustomerCompanys[cI.accId]?.value) {
          dispatch(
            updateData({
              key: 'step2',
              field: `${cI.accId}`,
              value: {value: cI.customerCompany, action: 'select'},
            })
          )
        }
      })
    }
  }, [companysInfo])

  const onChangeCompanysInfo = (accId: number, cc: TCompany | null) => {
    const newCompanysInfo = companysInfo.map((ci) => {
      if (ci.accId === accId) {
        return {accId, customerCompany: cc}
      }
      return ci
    })

    setCompanysInfo(newCompanysInfo)
  }

  const onSelectCustomerCompany = (accId: number) => (cc: TCompany | null) => {
    onChangeCompanysInfo(accId, cc)
  }

  const onAddCustomerCompany = (accId: number) => (cc: TCompany) => {
    dispatch(addCustomerCompanyAction({company: cc, accId}))
  }

  const onCancelSelection = (accId: number) => () => {
    onChangeCompanysInfo(accId, null)

    dispatch(
      updateData({
        key: 'step2',
        field: accId.toString(),
        value: null,
      })
    )
  }

  return (
    <div>
      <p className='mb-8 fs-5 text-gray-700'>
        Для правильного функционирования сервиса нам необходимо привязать личный кабинет к
        юридическому лицу, которое им владеет. Вы можете выбрать его из имеющихся или создать новое.
      </p>
      {selectedYMAccounts.map((acc) => (
        <div key={acc.id} className='mb-10'>
          <h6 className='fs-5 mb-5 text-gray-700 text-center'>
            {acc.business.name} ({acc.domain})
          </h6>
          <LinkingToLegalEntity
            onCancelSelection={onCancelSelection(acc.id)}
            selectedCC={currentCustomerCompanys[acc.id]?.value}
            action={currentCustomerCompanys[acc.id]?.action}
            onSelectCustomerCompany={onSelectCustomerCompany(acc.id)}
            addCustomerCompanyHandler={onAddCustomerCompany(acc.id)}
          />
        </div>
      ))}
    </div>
  )
}

export default YMStep3
