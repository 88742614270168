import {useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  useGetCodePhoneFromOzonMutation,
  usePostCodePhoneFromOzonMutation,
  usePostEmailCodeFromOzonMutation,
} from '../../../../../store/mpWizard/api'
import {nextStep} from '../../../../../store'

import {Button} from 'react-bootstrap'
import PhoneInput from './PhoneInput'
import CodeInput from './CodeInput'

import {StorageType} from '../../../../../../setup'
import {IUserModel} from '../../../../auth/models/UserModel'

interface TStepData {
  phone: string
  phoneCode: string
  emailCode: string
}

type TCoockiesStep = 'phone' | 'phoneCode' | 'email'

const OzonStep3 = () => {
  const [stepData, setStepData] = useState<TStepData>({
    phone: '',
    phoneCode: '',
    emailCode: '',
  })

  const user: IUserModel = useSelector((storage: StorageType) => storage.auth.user)

  const [cookiesStep, setCookiesStep] = useState<TCoockiesStep>('phone')

  const [getCodePhoneFromOzon, {isLoading: isLoadingGetPhoneCode, isError: isErrorGetPhoneCode}] =
    useGetCodePhoneFromOzonMutation()

  const [postCodePhoneFromOzon, {isLoading: isPostingPhoneCode, isError: isErrorPostingPhoneCode}] =
    usePostCodePhoneFromOzonMutation()

  const [postEmailCodeFromOzon, {isLoading: isPostingEmailCode, isError: isErrorEmailCode}] =
    usePostEmailCodeFromOzonMutation()

  const dispatch = useDispatch()

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>, inputValue: string = '') => {
    const {value, name} = e.target

    if (name === 'phone') {
      setStepData((prev) => ({...prev, [name]: inputValue}))
      return
    }

    setStepData((prev) => ({...prev, [name]: value}))
  }

  const onSendPhone = async (phone: string) => {
    if (!phone) {
      return
    }

    try {
      await getCodePhoneFromOzon({
        phone_number: phone,
        sd_id: user.id,
      }).unwrap()

      setCookiesStep('phoneCode')
    } catch (error) {}
  }

  const onSendCodePhone = async (phone: string, phoneCode: string) => {
    if (!phoneCode) {
      return
    }

    setStepData((prev) => ({...prev, phoneCode: ''}))

    try {
      await postCodePhoneFromOzon({
        phone_number: phone,
        code: phoneCode,
      }).unwrap()

      setCookiesStep('email')
    } catch (error) {}
  }

  const onSendEmailCode = async (emailCode: string, phone: string) => {
    if (!emailCode) {
      return
    }

    setStepData((prev) => ({...prev, emailCode: ''}))

    try {
      await postEmailCodeFromOzon({
        phone_number: phone,
        code: emailCode,
      }).unwrap()

      dispatch(nextStep())
    } catch (error) {}
  }

  const onConfirm = (action: TCoockiesStep, stepData: TStepData) => () => {
    const {phone, phoneCode, emailCode} = stepData

    const transformedPhone = phone.slice(1)

    if (action === 'phone') {
      onSendPhone(transformedPhone)
    } else if (action === 'email') {
      onSendEmailCode(emailCode, transformedPhone)
    } else if (action === 'phoneCode') {
      onSendCodePhone(transformedPhone, phoneCode)
    } else {
      return
    }
  }

  const onChangeNumber = () => {
    setCookiesStep('phone')

    setStepData({
      phone: '',
      phoneCode: '',
      emailCode: '',
    })
  }

  return (
    <div>
      <p className='mb-8 fs-5 text-gray-700'>
        Пожалуйста, введите номер телефона, привязанный к вашему личному кабинету{' '}
        <span className='fw-bold'>Ozon</span>. Это необязательный шаг, но без него не будет
        возможности загрузить отзывы с Ozon в наш сервис.
      </p>

      {cookiesStep === 'phone' && (
        <PhoneInput
          onChangeInput={onChangeInput}
          disabled={isLoadingGetPhoneCode}
          isError={isErrorGetPhoneCode}
        />
      )}

      {cookiesStep === 'phoneCode' && (
        <CodeInput
          value={stepData.phoneCode}
          onChangeInput={onChangeInput}
          name='phoneCode'
          isError={isErrorPostingPhoneCode}
          errorMessage='Неверный код'
          disabled={isPostingPhoneCode}
          placeholder='Введите код из СМС'
        />
      )}

      {cookiesStep === 'email' && (
        <CodeInput
          value={stepData.emailCode}
          onChangeInput={onChangeInput}
          name='emailCode'
          isError={isErrorEmailCode}
          errorMessage='Неверный код'
          disabled={isPostingEmailCode}
          placeholder='Введите код из электронной почты'
        />
      )}

      <div className='d-flex gap-3'>
        <Button
          disabled={isLoadingGetPhoneCode || isPostingPhoneCode || isPostingEmailCode}
          onClick={onConfirm(cookiesStep, stepData)}
        >
          Подтвердить
        </Button>
        {cookiesStep === 'phoneCode' && (
          <Button variant='secondary' onClick={onChangeNumber} disabled={isPostingPhoneCode}>
            Изменить номер
          </Button>
        )}
      </div>
    </div>
  )
}

export default OzonStep3
