import {call, put, select, takeEvery} from 'redux-saga/effects'
import {sagaUtils} from '../../utils/dataUtils'
import {setCount, setIsLoading, setMpDocs, setSelectedMpDoc, setSomeMpDocs} from './reducers'
import {
  ASYNC_GET_MPDOC,
  ASYNC_GET_MPDOCS_WITH_FILTER,
  ASYNC_GET_STICKER,
  SET_MPDOCS_REQUEST_PARAMETERS,
} from './actions'
import {getMpDocFromServer, getMpDocStickersOnServer, getMpDocsWithFilterFromServer} from './api'
import {intl} from '../../modules/goods/item/validation'
import {setRequestParams} from './reducers'
import {IUrlParameters} from '../../components/lists/UrlFilteredList'
import {emptyUrlParameters} from '../../utils/constants'
import {StorageType} from '../../../setup'
import {IMpDocStorage} from './types'

export function* mpdocSaga() {
  yield takeEvery(ASYNC_GET_MPDOCS_WITH_FILTER, workerGetMpDocsWithFilter)
  yield takeEvery(SET_MPDOCS_REQUEST_PARAMETERS, workerSetMpDocsRequestParameters)
  yield takeEvery(ASYNC_GET_MPDOC, workerGetMpDoc)
  // yield takeEvery(ASYNC_GET_STICKER, workerGetMpDocSticker)
}

// gets mpdoc sticers
// export function* workerGetMpDocSticker(action: any):any{
//     yield sagaUtils.workerWrapper(setIsLoading,
//         getMpDocStickersOnServer, action.payload,
//         setSticker, null,
//         intl.formatMessage({id: "SAGAS.MPDOCSTICKERS.GET"}), "GET", "data", false)
// }

// get mpdocs from server, using filters in redux
export function* workerGetMpDocsWithFilter(): any {
  const mpdocState: IMpDocStorage = yield select((store: StorageType) => store.mpdocs)
  const params: IUrlParameters = mpdocState.filter ?? emptyUrlParameters

  yield sagaUtils.workerWrapper(
    setIsLoading,
    getMpDocsWithFilterFromServer,
    params,
    setMpDocs,
    null,
    intl.formatMessage({id: 'SAGAS.MPDOCS.GET'}),
    'GET',
    'data.results',
    false,
    '',
    undefined,
    {action: setCount, resultType: 'data', resultTypePropertyName: 'count', params: null}
  )
}

// set request parameters
export function* workerSetMpDocsRequestParameters(action: any): any {
  yield put(setRequestParams(action.payload))
}

// gets mpdoc by id and set it to storage
export function* workerGetMpDoc(action: any): any {
  yield sagaUtils.workerWrapper(
    setIsLoading,
    getMpDocFromServer,
    action.payload,
    setSelectedMpDoc,
    null,
    intl.formatMessage({id: 'SAGAS.MPDOC.GET'}),
    'GET',
    'data',
    false
  )
}
