import clsx from 'clsx'
import {useSelector} from 'react-redux'
import {IUserModel} from '../../../../app/modules/auth/models/UserModel'

import {StorageType} from '../../../../setup'
import {toAbsoluteUrl} from '../../../helpers'

import {useLayout} from '../../core'
import {MenuItemProfile} from './MenuItemProfile'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3'

interface ITopbar {
  to?: string
}
const Topbar = (props: ITopbar) => {
  const user: IUserModel = useSelector((state: StorageType) => state.auth.user)
  const initials: string =
    user?.firstname[0] && user?.lastname[0] ? user?.firstname[0] + user?.lastname[0] : 'S'
  const {config} = useLayout()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div
        // className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        className={clsx('d-flex', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        {/* <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img src={toAbsoluteUrl('/media/svg/avatars/blank.svg')} alt='metronic' />
        </div> */}
        {/* <HeaderUserMenu /> */}

        <div
          className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-white menu-state-title-sbx-muted menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold  align-items-stretch justify-content-end'
          id='#kt_header_menu_profile'
          data-kt-menu='true'
        >
          <MenuItemProfile
            icon={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
            title={
              user?.firstname?.length || user?.lastname?.length
                ? `${user?.firstname} ${user?.lastname}`
                : user.email
            }
            subtitle={`Баланс: `}
            to={props.to ? props.to : 'account'}
            noActive={props.to}
            userInitials={initials === '' ? 'S' : initials}
          />
        </div>
      </div>
      {/* <Button >Кнопка</Button> */}
      {/* end::Toggle */}

      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Topbar}
