import {useEffect, useState} from 'react'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {actions} from '../../../../store/auth/AuthRedux'

import LeftArrowButton from '../../../../components/buttons/LeftArrowButton'
import OTPInput from './OTPInput'
import CountdownTimer from './CountdownTimer'
import ChangeNumber from './ChangeNumber'

import {StorageType} from '../../../../../setup'
import {IUserModel} from '../../models/UserModel'
import {IAuthState} from '../../../../store/auth/AuthRedux'

const TWO_MINUTES_IN_MS = 2 * 60 * 1000
const NOW_IN_MS = new Date().getTime()

const CALL_ERROR = 'Произошла ошибка при инициализации звонка'
const CODE_ERROR = 'Введен неправильный код'

const NumberConfirm = () => {
  const [otp, setOTP] = useState(new Array(4).fill(''))
  const [changeNumber, setChangeNumber] = useState(false)
  const [targetDate, setTargetDate] = useState(NOW_IN_MS + TWO_MINUTES_IN_MS)

  const user: IUserModel = useSelector((storage: StorageType) => storage.auth.user, shallowEqual)
  const {confirmationStatus, confirmationLoading}: IAuthState = useSelector(
    (storage: StorageType) => storage.auth,
    shallowEqual
  )

  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(actions.getCall(user?.id))
  }, [])

  const clearOTP = () => {
    setOTP(new Array(4).fill(''))
  }

  const sendAuthCode = (userId: number) => (code: string) => {
    dispatch(actions.sendAuthCode(userId, code))
  }

  const onFocusOTPInput = () => {
    if (confirmationStatus === CODE_ERROR) {
      dispatch(actions.setConfirmationStatus(''))
    }
  }

  const onExitToLogin = () => {
    navigate('/auth/login')
    dispatch(actions.logout())
  }

  const onChangeTargetDate = (newDate: number) => {
    const newTargetDate = new Date().getTime() + newDate
    setTargetDate(newTargetDate)
  }

  return changeNumber ? (
    <ChangeNumber />
  ) : (
    <div className='position-relative'>
      <h2 className='fw-normal mb-4'>Подтверждение номера телефона</h2>
      <p className='mb-5 fs-6'>
        В течение нескольких минут на Ваш телефон <span className='fw-bolder'>+{user?.phone}</span>{' '}
        поступит звонок-сброс с уникального номера. На него не нужно отвечать.
      </p>
      <p className='fw-bolder mb-6 fs-6'>Введите последние четыре цифры входящего номера</p>
      <LeftArrowButton
        colorName='3'
        className='position-absolute translate-middle'
        style={{top: '-30px', left: '-30px'}}
        onClick={onExitToLogin}
      />
      <OTPInput
        values={otp}
        isDisabled={confirmationStatus === CALL_ERROR}
        onOTPSubmit={sendAuthCode(user?.id)}
        isLoading={confirmationLoading}
        onFocusInput={onFocusOTPInput}
        error={confirmationStatus}
      />
      <CountdownTimer
        targetDate={targetDate}
        userId={user?.id}
        clearOTP={clearOTP}
        setTargetDate={onChangeTargetDate}
      />
      <p
        style={{cursor: 'pointer'}}
        className='text-center fs-6 mb-5 text-primary'
        onClick={() => setChangeNumber(true)}
      >
        Зарегистрироваться под другим номером
      </p>
    </div>
  )
}

export default NumberConfirm
