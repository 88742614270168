import { isObject, useField, useFormikContext } from "formik"
import { FC } from "react"
import { useIntl } from "react-intl"
const isDebug = false


interface IFormikFieldErrorProps {
    ObjectValueName?: string
    fieldName: string
    customErrorFieldPath?: string
    message?: string
    className?: string
}


//
// **  this control can display error information by formik subfield
// ** name of subfield sets in ObjectValueName property. 
// ** for example, if you need to show error by field Formik.values.field1, but error excepts in 
// ** Formik.values.field1.value1 , you can make <FormikFieldError fieldName="field1" ObjectValueName="value1" /> in  
// ** Formik.values.field1 conrol and it shows that error
//
const FormikFieldError: FC<IFormikFieldErrorProps> = (props) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField(props.fieldName)
    const [fieldError, metaError, helpersError] = useField(props.customErrorFieldPath ?? props.fieldName)
    let errorMessage = ""

    if(isObject(metaError.error)) {
        // here we make a string, which then convert to object and then sets this variable with converted meta.error
        let obj = metaError.error as unknown as object
        let o = "{}"
        let b = eval(o) //  if we don't do so, typescript shows us an error
        b = obj
        errorMessage = props.ObjectValueName ? b[props.ObjectValueName] : intl.formatMessage({id:"COMPONENTS.FIELDS.ERROR.PARAMETERNOTFOUND"})
    }
    else{
        errorMessage = metaError.error ?? ""
    }

    return meta.touched && metaError.error
        ? <div className={`text-danger mt-2 ${props.className ?? ""}`}>
            {props.message ? props.message + ' ' + errorMessage : errorMessage}
            {(isDebug && meta.error) ? " - DEBUG - " + errorMessage : null}
            {isDebug ? <div>touched: {meta.touched ? "true" : "false"}</div> : null}
        </div>
        : null
}

export default FormikFieldError