export const UpdateIcon = () => {
  return (
    <svg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.8958 19.8623C13.9916 20.3415 13.6083 20.9166 13.1291 21.0124C12.65 21.1083 12.075 21.1082 11.5958 21.1082C9.10415 21.1082 6.61248 20.1498 4.79165 18.329C1.34165 14.879 1.05413 9.32068 4.1208 5.58318L5.46249 6.92488C3.16249 9.89566 3.44997 14.2082 6.13331 16.9873C7.8583 18.7123 10.35 19.5749 12.8416 19.0958C13.3208 18.9999 13.8 19.3832 13.8958 19.8623ZM17.6333 16.1248L18.975 17.4665C21.9458 13.729 21.7541 8.17069 18.2083 4.72069C16.0041 2.51652 12.9375 1.5582 9.87081 2.03737C9.39163 2.1332 9.00831 2.61238 9.10415 3.09154C9.19998 3.57071 9.67915 3.95403 10.1583 3.8582C12.5541 3.47487 15.0458 4.24154 16.8666 5.96654C19.6458 8.84154 19.8375 13.154 17.6333 16.1248Z'
        fill='#464E5F'
      />
      <path
        opacity='0.3'
        d='M1.91669 3.47484H6.70835C7.28335 3.47484 7.66669 3.85817 7.66669 4.43317V9.22481L1.91669 3.47484ZM15.3334 13.8248V18.6165C15.3334 19.1915 15.7167 19.5748 16.2917 19.5748H21.0834L15.3334 13.8248Z'
        fill='#464E5F'
      />
    </svg>
  )
}
