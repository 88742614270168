/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {Form, Formik, FormikValues, FormikHelpers, FormikProps} from 'formik'
import * as Yup from 'yup'
import * as auth from '../../../store/auth/AuthRedux'
import {login} from '../../../store/auth/AuthCRUD'
import useAbilityContext from '../../../hooks/useAbilityContext'
import {intl} from '../../goods/item/validation'
import {useIntl} from 'react-intl'

import TextFieldRow from '../../goods/item/tabs/TabInformation/Fields/TextFieldRow'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.INVALID_EMAIL'}))
    .min(3, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.EMAIL'})),
  password: Yup.string()
    .min(3, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORD'})),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()

  const [showPassword, setShowPassword] = useState(false)

  const ability = useAbilityContext()

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onToggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const onSubmit = (
    values: {email: string; password: string},
    {setStatus, setSubmitting}: FormikHelpers<{email: string; password: string}>
  ) => {
    setLoading(true)
    setTimeout(() => {
      login(values.email, values.password)
        .then(({data}) => {
          setLoading(false)
          dispatch(auth.actions.login(data, ability))
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false)
          setStatus(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.LOGINDETAILINCORRECT'}))
        })
    }, 1000)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={onSubmit}>
      {(formikProps: FormikProps<FormikValues>) => (
        <Form
          className='form w-100'
          onSubmit={formikProps.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {formikProps.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formikProps.status}</div>
            </div>
          )}

          <TextFieldRow
            fieldName='email'
            className='fv-row mb-6'
            visualValidation
            placeholder={intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.EMAIL'})}
            label={intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.EMAIL'})}
            defaultEmptyValue=''
            type='email'
            isClearBtn
            otherProps={{autoComplete: 'off'}}
          />

          <TextFieldRow
            fieldName='password'
            className='fv-row mb-6'
            visualValidation
            label={intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.PASSWORD'})}
            placeholder={intl.formatMessage({id: 'AUTH.LOGIN.CAPTION.PASSWORD'})}
            defaultEmptyValue=''
            type={showPassword ? 'text' : 'password'}
            isClearBtn
            rightIcon={
              showPassword ? (
                <i className='bi bi-eye fs-2 text-dark' onClick={onToggleShowPassword}></i>
              ) : (
                <i className='bi bi-eye-slash fs-2 text-dark' onClick={onToggleShowPassword}></i>
              )
            }
            otherProps={{autoComplete: 'off'}}
          />

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formikProps.isSubmitting || !formikProps.isValid}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'AUTH.LOGIN.BUTTONS.LOGIN'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'AUTH.LOGIN.STATUS.LOGIN'})}...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </Form>
      )}
    </Formik>
  )
}
