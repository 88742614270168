/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, MouseEvent} from 'react'
import {useDispatch} from 'react-redux'
import {NavLink} from 'react-router-dom'
import {Formik, Form, FormikHelpers, FormikProps} from 'formik'
import useAbilityContext from '../../../hooks/useAbilityContext'
import * as Yup from 'yup'
import clsx from 'clsx'
import {startsWith} from 'lodash'
import * as auth from '../../../store/auth/AuthRedux'
import {login, register} from '../../../store/auth/AuthCRUD'
import {intl} from '../../goods/item/validation'
import {useIntl} from 'react-intl'

import PhoneInput from '../../../components/inputs/PhoneInput'
import TextFieldRow from '../../goods/item/tabs/TabInformation/Fields/TextFieldRow'
import CheckBoxCustom from '../../../components/inputs/CheckBoxCustom'

type TAuthValues = {
  firstname: string
  lastname: string
  email: string
  phone: string
  password: string
  password2: string
  customer_name: {
    name: string
  }
}

const loginSchema = Yup.object().shape({
  firstname: Yup.string()
    .matches(/^(?!-)[a-zA-Zа-яА-ЯЁё]*-?[a-zA-Zа-яА-ЯЁё]+$/, {
      message: intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.ONLYLETTERS'}),
    })
    .min(1, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.NAME'})),
  lastname: Yup.string()
    .matches(/^(?!-)[a-zA-Zа-яА-ЯЁё]*-?[a-zA-Zа-яА-ЯЁё]+$/, {
      message: intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.ONLYLETTERS'}),
    })
    .min(1, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.SURNAME'})),
  email: Yup.string()
    .email(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.INVALID_EMAIL'}))
    .min(3, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN3SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.EMAIL'})),
  phone: Yup.string()
    .min(11, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PHONE11SYMBOLS'}))
    .max(13, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX13SYMBOLS'}))
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PHONE11SYMBOLS'})),
  password: Yup.string()
    .min(8, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MIN8SYMBOLS'}))
    .max(50, intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.MAX50SYMBOLS'}))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/, {
      message: intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORDREGEX'}),
    })
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORD'})),
  password2: Yup.string()
    .required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORD2'}))
    .oneOf(
      [Yup.ref('password'), null],
      intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.PASSWORDNOTMATCH'})
    ),
  customer_name: Yup.object({
    name: Yup.string().required(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.COMPANYNAME'})),
  }),
})

const initialValues: TAuthValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  password2: '',
  customer_name: {
    name: '',
  },
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Register() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [isOfferConfirmed, setIsOfferConfirmed] = useState(false)
  const [validPhoneMessage, setValidPhoneMessage] = useState('')
  const dispatch = useDispatch()
  const ability = useAbilityContext()

  const [showPasswords, setShowPasswords] = useState({
    password: false,
    pasword2: false,
  })

  const onToggleShowPasswords = (key: 'password' | 'pasword2') => () => {
    setShowPasswords({
      ...showPasswords,
      [key]: !showPasswords[key],
    })
  }

  const onChangeConfirmOffer = () => {
    setIsOfferConfirmed(!isOfferConfirmed)
  }

  const onSubmit = (
    values: TAuthValues,
    {setStatus, setSubmitting}: FormikHelpers<TAuthValues>
  ) => {
    setLoading(true)
    setTimeout(() => {
      register(values)
        .then(({data}) => {
          login(data.email, values.password)
            .then((resp: any) => {
              setLoading(false)
              // dispatch(auth.actions.register(resp.data, navRef.current, ability))
              dispatch(auth.actions.login(resp.data, ability))
            })
            .catch(() => {
              setLoading(false)
              setSubmitting(false)
              setStatus(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.LOGINDETAILINCORRECT'}))
            })
        })
        .catch((err) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(intl.formatMessage({id: 'AUTH.LOGIN.VALIDATION.REGISTERDETAILINCORRECT'}))
        })
    }, 1000)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={onSubmit}>
      {(formikProps: FormikProps<TAuthValues>) => (
        <Form
          className='form w-100'
          onSubmit={formikProps.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          {formikProps.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formikProps.status}</div>
            </div>
          )}

          <TextFieldRow
            fieldName='firstname'
            className='fv-row mb-6'
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.NAME'})}
            label={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.NAME'})}
            defaultEmptyValue=''
            visualValidation
            type='text'
            isClearBtn
            otherProps={{autoComplete: 'off'}}
          />

          <TextFieldRow
            fieldName='lastname'
            className='fv-row mb-6'
            visualValidation
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.SURNAME'})}
            label={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.SURNAME'})}
            defaultEmptyValue=''
            type='text'
            isClearBtn
            otherProps={{autoComplete: 'off'}}
          />

          <TextFieldRow
            fieldName='email'
            className='fv-row mb-6'
            visualValidation
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.EMAIL'})}
            label={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.EMAIL'})}
            defaultEmptyValue=''
            type='email'
            isClearBtn
            otherProps={{autoComplete: 'off'}}
          />

          <PhoneInput
            wrapperClassName='fv-row mb-10'
            onChange={(value) => formikProps.setFieldValue('phone', value)}
            inputProps={{
              country: 'ru',
              onlyCountries: ['ru'],
              disableDropdown: true,
              onBlur: () => formikProps.setFieldTouched('phone', true),
              inputClass: clsx(
                'form-control form-control-lg form-control-solid w-100',
                {
                  'border border-danger bg-danger bg-opacity-10':
                    formikProps.touched.phone && (formikProps.errors.phone || validPhoneMessage),
                },
                {
                  'border border-success bg-success bg-opacity-10':
                    formikProps.touched.phone && !(formikProps.errors.phone || validPhoneMessage),
                }
              ),
            }}
            label={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.PHONE'})}
            hasErrors={Boolean(
              formikProps.touched.phone && (formikProps.errors.phone || validPhoneMessage)
            )}
            errors={formikProps.errors.phone || validPhoneMessage}
            validator={(inputNumber, country: any, countries) => {
              if (formikProps.touched.phone) {
                const isValid = countries.some((country: any) => {
                  return (
                    startsWith(inputNumber, country.dialCode) ||
                    startsWith(country.dialCode, inputNumber)
                  )
                })

                setValidPhoneMessage(
                  isValid
                    ? ''
                    : `Неверный формат номера. Введите в формате +${country.dialCode}XXXXXXXXXX, где X - цифра`
                )

                return isValid
              }

              return true
            }}
          />

          <TextFieldRow
            fieldName='customer_name.name'
            className='fv-row mb-6'
            visualValidation
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.COMPANYNAME'})}
            label={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.COMPANYNAME'})}
            defaultEmptyValue=''
            type='text'
            isClearBtn
            otherProps={{autoComplete: 'off'}}
          />

          <TextFieldRow
            fieldName='password'
            className='fv-row mb-6'
            visualValidation
            label={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.PASSWORD'})}
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.PASSWORD'})}
            defaultEmptyValue=''
            type={showPasswords.password ? 'text' : 'password'}
            isClearBtn
            otherProps={{autoComplete: 'off'}}
            rightIcon={
              showPasswords.password ? (
                <i
                  data-key='password'
                  className='bi bi-eye fs-2 text-dark'
                  onClick={onToggleShowPasswords('password')}
                ></i>
              ) : (
                <i
                  data-key='password'
                  className='bi bi-eye-slash fs-2 text-dark'
                  onClick={onToggleShowPasswords('password')}
                ></i>
              )
            }
          />

          <TextFieldRow
            fieldName='password2'
            className='fv-row mb-6'
            visualValidation
            label={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.PASSWORD2'})}
            placeholder={intl.formatMessage({id: 'AUTH.REGISTER.CAPTION.PASSWORD2'})}
            defaultEmptyValue=''
            type={showPasswords.pasword2 ? 'text' : 'password'}
            isClearBtn
            otherProps={{autoComplete: 'off'}}
            rightIcon={
              showPasswords.pasword2 ? (
                <i
                  data-key='password2'
                  className='bi bi-eye fs-2 text-dark'
                  onClick={onToggleShowPasswords('pasword2')}
                ></i>
              ) : (
                <i
                  data-key='password2'
                  className='bi bi-eye-slash fs-2 text-dark'
                  onClick={onToggleShowPasswords('pasword2')}
                ></i>
              )
            }
          />

          {/* begin::Action */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 mb-6'
              disabled={
                formikProps.isSubmitting ||
                !formikProps.isValid ||
                Boolean(validPhoneMessage) ||
                !isOfferConfirmed
              }
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'AUTH.REGISTER.BUTTONS.REGISTER'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'AUTH.REGISTER.STATUS.REGISTER'})}...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}

          <div className='form-check form-check-custom form-check-solid align-items-start'>
            <CheckBoxCustom id='offer' onChange={onChangeConfirmOffer} />
            <label htmlFor='offer' className='form-check-label'>
              Регистрируясь, я соглашаюсь с{' '}
              <a target='_blank' href='/media/docs/contract.pdf' rel='noopener noreferrer'>
                договором публичной оферты
              </a>
            </label>
          </div>
        </Form>
      )}
    </Formik>
  )
}
