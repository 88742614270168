import axios from 'axios'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'
import {
  TRegisterYMTokenRequest,
  TGetCaptchaRequest,
  IGetOzonCodeRequest,
  IPostOzonCodeRequest,
} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`
// const API_URL_OZON_COOKIE = 'http://10.44.10.132:8015/api/'
const API_URL_OZON_COOKIE = 'https://oz-cookie.d.x09.ru/api/'
export const USERS_URL = `${API_URL}v2/users/`
export const TOKENS_URL = `${API_URL}v2/tokens/`

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/get_captcha/

export const getCaptcha = (data: TGetCaptchaRequest) => {
  return axios.post(`${USERS_URL}get_captcha/`, {phone_number: data.phone}, {responseType: 'blob'})
}

// #endregion

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_captcha/

export const postCaptcha = (captcha: string) => {
  return axios.post(`${USERS_URL}post_captcha/`, {captcha_code: captcha})
}

// #endregion

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_phone_code/

export const postCodePhone = (code: string) => {
  return axios.post(`${USERS_URL}post_phone_code/`, {phone_code: code})
}

// #endregion

// #region
//https://api-dev.shop-delivery.ru/int_api/v2/users/post_phone_code/

export const registerYMToken = ({id, validation_code}: TRegisterYMTokenRequest) => {
  return axios.get(`${TOKENS_URL}${id}/registrate_ym_token/?auth_key=${validation_code}`)
}

// #endregion

export const ozonCookieApiSlice = createApi({
  reducerPath: 'ozonCookieApi',
  baseQuery: fetchBaseQuery({baseUrl: API_URL_OZON_COOKIE}),
  endpoints: (builder) => ({
    getCodePhoneFromOzon: builder.mutation<void, IGetOzonCodeRequest>({
      query: (body) => ({
        url: 'authorize_account',
        method: 'POST',
        body,
      }),
    }),
    postCodePhoneFromOzon: builder.mutation<void, IPostOzonCodeRequest>({
      query: (body) => ({
        url: 'confirm_phone_code',
        method: 'POST',
        body,
      }),
    }),
    postEmailCodeFromOzon: builder.mutation<void, IPostOzonCodeRequest>({
      query: (body) => ({
        url: 'confirm_email_code',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetCodePhoneFromOzonMutation,
  usePostCodePhoneFromOzonMutation,
  usePostEmailCodeFromOzonMutation,
} = ozonCookieApiSlice
