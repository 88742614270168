import axios from 'axios'
import {ICustomerCompany} from './types'

const API_URL = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ENDPOINT_PREFIX}`

export const CUSTOMER_COMPANYS_URL = `${API_URL}v2/customers_companies/`
export const AUTOCOMPLETE_URL = `${API_URL}v2/autocomplete/companies`

export const fetchCustomerCompanys = () => {
  return axios.get(CUSTOMER_COMPANYS_URL)
}

export const addCustomerCompanyToServer = (params: ICustomerCompany) => {
  let requestParams: any = params
  delete requestParams.id
  return axios.post<ICustomerCompany>(CUSTOMER_COMPANYS_URL, requestParams)
}

export const updateCustomerCompanyOnServer = (params: ICustomerCompany) => {
  console.log('updateCustomerCompanyOnServer', params)
  return axios.put<ICustomerCompany>(`${CUSTOMER_COMPANYS_URL}${params.id}/`, params)
}

export const deleteCustomerCompanyOnServer = (companyId: number) => {
  console.log('deleteCustomerCompanyOnServer', companyId)
  return axios.delete<ICustomerCompany>(`${CUSTOMER_COMPANYS_URL}${companyId}/`)
}

export const autocompleteCompany = (query: string) => {
  return axios
    .get<ICustomerCompany[]>(`${AUTOCOMPLETE_URL}?query=${query}`)
    .then((res) => res.data)
    .catch(() => [])
}
