import {ICustomerCompany} from '../../../store/customerCompanys/types'
import {AuthModel} from './AuthModel'
import {UserAddressModel} from './UserAddressModel'
import {UserCommunicationModel} from './UserCommunicationModel'
import {UserEmailSettingsModel} from './UserEmailSettingsModel'
import {UserSocialNetworksModel} from './UserSocialNetworksModel'

// export interface IUserModel {
//   user: any
//   user_personal_info: any
//   userCustomer: any
//   user_account_info: {
//     username: string
//     email: string
//     language: string
//     time_zone: string
//     communication: {
//       email: boolean
//       sms: boolean
//       phone: boolean
//     }
//     verification: boolean
//   }
// }

export interface ICustomer {
  id: number
  name: string
  email: string
  address: string
  companies: ICustomerCompany[]
  phone: string
  yur_name: string
  inn: string
  kpp: string
  bank_account_details: string
  yur_address: string
  created_at: string | null
  updated_at: string | null
  contract: string
  balance: string
  // apart: string
  // house: string
  // street: string
  params: any
}

export interface ICustomerAdd {
  customer: ICustomer
  userid: number
}

export const emptyCustomer: ICustomer = {
  // apart: "",
  // street: "",
  // house: "",
  address: '',
  bank_account_details: '',
  companies: [],
  contract: '',
  created_at: null,
  email: '',
  id: -1,
  inn: '',
  kpp: '',
  name: '',
  params: null,
  phone: '',
  updated_at: null,
  yur_address: '',
  yur_name: '',
  balance: '0',
}

export interface IAccessPath {
  id: number
  code: string
  name: string
  params: any
}

export interface IUserModel {
  id: number
  email: string
  firstname: string
  lastname: string
  password: string
  token: string
  phone?: string
  params: {
    tg_code?: string
    tg_id?: number
    number_confirm: boolean
    ozon_auth?: {
      cookie: string
    }
  }
  customer: ICustomer
  access_group: IAccessPath[]
}

export interface IUserToEditModel {
  id: number
  email: string
  firstname: string
  lastname: string
  phone?: string
}

export const emptyUser: IUserModel = {
  customer: emptyCustomer,
  email: '',
  firstname: '',
  id: -1,
  lastname: '',
  password: '',
  token: '',
  params: {
    number_confirm: false,
  },
  access_group: [],
}

export interface IRegisterUserModel {
  email: string
  firstname: string
  lastname: string
  password: string
  phone: string
  customer_name: {
    name: string
  }
}

export const emptyRegisterUser: IRegisterUserModel = {
  email: '',
  firstname: '',
  lastname: '',
  password: '',
  phone: '',
  customer_name: {
    name: '',
  },
}

// export interface UserModel {
//   id: number
//   username: string
//   password: string | undefined
//   email: string
//   first_name: string
//   last_name: string
//   fullname?: string
//   occupation?: string
//   companyName?: string
//   phone?: string
//   roles?: Array<number>
//   pic?: string
//   language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
//   timeZone?: string
//   website?: 'https://keenthemes.com'
//   emailSettings?: UserEmailSettingsModel
//   auth?: AuthModel
//   communication?: UserCommunicationModel
//   address?: UserAddressModel
//   socialNetworks?: UserSocialNetworksModel
// }
